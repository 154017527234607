








import { Gsh, GshStatus } from '@/lib/gsh';
import { StructuredOrder, StructuredRun } from '@/lib/model';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class JobStatus extends Vue {
  @Prop() private order!: StructuredOrder;
  @Prop() private run!: StructuredRun;

  get jobStatus():GshStatus{
    return Gsh.jobStatus(this.order, this.run, this.order._estimatedArrivalTime);
  }
  
}
