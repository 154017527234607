
































import { ToastMixin } from '@/mixins/ToastMixin';
import { BModal } from 'bootstrap-vue';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator'
import { gshApi } from "@/lib/AxiosPlugin";

@Component({})
export default class ReportProblem extends mixins(ToastMixin) {
  description = '';

  async reportProblem():Promise<void>{
    const state = JSON.stringify(this.$store.state);

    try{
      await gshApi.submitReport(this.description, state);
      this.makeToast('Reporter', 'Problem has been reported.');
      (this.$refs['report-problem-ref'] as BModal).hide();
    }catch(e){
      this.makeToast('Reporter', 'Failed to report problem');
      alert('Reporter failed - please email damien@26brains.com');
    }
  }
}

