import Vue from 'vue';
import Vuex from 'vuex';
import { dashboard, DashboardState } from "./dashboard-store";
import { overrides, OverridesState } from "./overrides-store";
import { auth, AuthState } from "./auth-store";

Vue.use(Vuex);
export interface State {
  dashboard: DashboardState;
  overrides: OverridesState;
  auth: AuthState;
}

export const store = new Vuex.Store({
  modules: {
    dashboard,
    overrides,
    auth,
  },
      // async persistState({ state }:OverridesContext){
    //   window.localStorage.setItem('overridesState', JSON.stringify(state));
    // },
})
