







































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SyncMixin } from '@/mixins/SyncMixin'
import { SearchMixin } from '@/mixins/SearchMixin'
import { StructuredOrder } from '@/lib/model';
import OrderView from '@/components/OrderView.vue'
import '../styles/warehouse-styles.css';

@Component({
  components: {
    OrderView
  }
})
export default class WarehouseLayout extends mixins(SyncMixin, SearchMixin) {
  get collectionOrders(): StructuredOrder[]{
    return this.$store.getters.getWarehouseCollections(this.formattedDate, this.searchTerm);
  }
  get returnOrders(): StructuredOrder[]{
    return this.$store.getters.getWarehouseReturns(this.formattedDate, this.searchTerm);
  }
}

