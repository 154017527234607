import { ActionContext } from 'vuex';
import { Credentials, TokenResponse } from "./model";
import { State } from "./store";
// import router from '../router'
import jwt, { JwtPayload } from 'jsonwebtoken';
import { gshApi } from "@/lib/AxiosPlugin";

const version = 1.2;


export type AxiosError = {
  request: {
    response: string
  }
} & Error

export type ErrorResponse = {
  msg: string,
}

export type AuthState = {
  version: number,
  token: string | undefined,
  refresh_token: string | undefined,
  loggingIn: boolean,
  error: string | undefined,
  loggedIn: boolean,
}

export type AuthContext = ActionContext<AuthState, State>

const emptyState:AuthState = {
  version,
  token: undefined,
  refresh_token: undefined,
  loggingIn: false,
  error: undefined,
  loggedIn: false,
};

function initialiseState():AuthState{
  const authState = window.localStorage.getItem('authState');
  if(!authState){
    return emptyState;
  }
  try{
    const state = JSON.parse(authState);
    if(typeof(state.version) !== 'number' || state.version < version){
      console.log('State version has changed.');
      return emptyState;
    }
    return state;
  }catch{
    console.log('Could not unfreeze state');
    return emptyState;
  }
}

const authState = initialiseState();
// const authState = emptyState;

export const auth = {
  state: ():AuthState => authState,
  mutations: {
    setToken(state:AuthState, token: string):void{
      state.token = token;
    },
    setRefreshToken(state:AuthState, refresh_token: string):void{
      state.refresh_token = refresh_token;
    },
    setLoggingIn(state:AuthState, loggingIn:boolean):void{
      state.loggingIn = loggingIn;
    },
    setLoggedIn(state:AuthState, loggedIn: boolean):void{
      state.loggedIn = loggedIn;
    },
    setError(state:AuthState, error: string):void{
      state.error = error;
    },
  },
  actions: {
    async persistAuthState({ state }:AuthContext):Promise<void>{
      window.localStorage.setItem('authState', JSON.stringify(state));
    },
    async doLogin({ commit, dispatch }:AuthContext, credentials:Credentials):Promise<void>{
      try{
        commit('setLoggingIn', true);
        commit('setError', '');
        const { username, password } = credentials;
        const { token, refresh_token } = await gshApi.authenticate(username, password);
        console.log('Setting token', token);
        commit('setToken', token);
        commit('setRefreshToken', refresh_token);
        commit('setLoggedIn', true);
      }catch(_e){
        const e = _e as AxiosError;
        commit('setToken', undefined);
        try{
          const error = (JSON.parse(e.request.response) as ErrorResponse).msg;
          commit('setError', error);
        }catch(_){
          commit('setError', e.message);
        }        
      }
      commit('setLoggingIn', false);
      await dispatch('persistAuthState');
    },
    async doLogout({ commit, dispatch }:AuthContext):Promise<void>{
      commit('setToken', undefined);
      commit('setRefreshToken', undefined);
      commit('setLoggedIn', false);
      await dispatch('persistAuthState');
    },
    async checkAuth({ state, commit }:AuthContext):Promise<void>{
      if(!isTokenValid(state.token)){
        return commit('setLoggedIn', false);
      }
      commit('setLoggedIn', true);
    },
    async setTokens({ commit, dispatch }:AuthContext, tokens: TokenResponse):Promise<void>{
      const {token, refresh_token } = tokens
      commit('setToken', token);
      commit('setRefreshToken', refresh_token);
      await dispatch('persistAuthState');
    }
  },
  getters: {
    token: (state:AuthState):string | undefined => {
      return state.token;
    },
    refresh_token: (state:AuthState):string | undefined => {
      return state.refresh_token;
    },
  //   loggedIn: (state:AuthState):boolean => {
  //     if(!state.token){
  //       return false;
  //     }
  //     const decoded = jwt.decode(state.token) as JwtPayload;
  //     if(!decoded || !decoded.exp){
  //       return false;
  //     }
  //     if((new Date(decoded.exp*1000)).toISOString() < (new Date()).toISOString()){
  //       return false;
  //     }
  //     return true;
  //   }
  }
}

function isTokenValid(token:string | undefined):boolean{
  if(!token){
    return false;
  }
  const decoded = jwt.decode(token) as JwtPayload;
  if(!decoded || !decoded.exp){
    return false;
  }
  return (new Date(decoded.exp * 1000)).toISOString() >= (new Date()).toISOString();
}