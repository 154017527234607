import Vue from 'vue';
import { ActionContext } from 'vuex';
import { Driver, Opportunity, OrderWithZone, Printer, StructuredOrder, StructuredRun } from "./model";
import { unfinishedRuns, finishedRuns, 
    reformatScheduleAsRuns, updateRunStartTimes, filterAndHydrate, filterVans, 
    extractNumbersFromRun, 
    filterWarehouseCollections,
    filterWarehouseAndHydrate,
    filterWarehouseReturns,
    morningRuns,
    afternoonRuns,
    eveningRuns} from "./dashboard-helpers";
import { uniqBy } from "lodash";
import moment from "moment";
import { State } from "./store";
import { MaxoptraHelper } from "./MaxoptraHelper";
import { gshApi } from "@/lib/AxiosPlugin";

export type RunStore = {
  [key: string]: StructuredRun[],
}

export type VisibilityState = {
  _runNumber: string,
  _id: string,
  visible: boolean
}

export type VisibilityStateStore = {
  [key: string]: VisibilityState[],
}

export type DashboardState = {
  version: number,
  tier: string,
  runs: RunStore,
  numbers: string[],
  opportunities: Opportunity[],
  visibilityStates: VisibilityStateStore,
  drivers: Driver[],
  printers: Printer[],
  ordersWithZone: OrderWithZone[],
  selectedPrinterId: number|null,
}

const version = 1.2;
const tier = process.env.VUE_APP_TIER || '';
const dateFormat = 'YYYY-MM-DD';


export type DashboardContext = ActionContext<DashboardState, State>

const emptyState:DashboardState = {
  version,
  tier,
  runs: {},
  numbers: [],
  opportunities: [],
  visibilityStates: {},
  drivers: [],
  printers: [],
  ordersWithZone: [],
  selectedPrinterId: null
};

function initialiseState():DashboardState{
  const frozenState = window.localStorage.getItem('frozenState');
  if(!frozenState){
    return emptyState;
  }
  try{
    const state = JSON.parse(frozenState);
    if(typeof(state.version) !== 'number' || state.version < version){
      console.log('State version has changed.');
      return emptyState;
    }
    if(state.tier !== tier){
      console.log('State tier has changed.');
      return emptyState;
    }
    return state;
  }catch{
    console.log('Could not unfreeze state');
    return emptyState;
  }
}

const dashboardState = initialiseState();
// const state = emptyState;

export const dashboard = {
  state: ():DashboardState => dashboardState,
  mutations: {
    setRuns(state:DashboardState, record: RunStore):void{
      Vue.set(state, 'runs', Object.assign({}, state.runs, record));
    },
    deleteRun(state:DashboardState, date:string):void{
      delete state.runs[date];
      Vue.set(state, 'runs', state.runs);
    },
    setDrivers(state:DashboardState, drivers: Driver[]):void{
      state.drivers = drivers;
    },
    setPrinters(state:DashboardState, printers: Printer[]):void{
      state.printers = printers;
    },
    setSelectedPrinterId(state:DashboardState, printerId: number):void{
      state.selectedPrinterId = printerId;
    },
    updateOrdersWithZone(state:DashboardState, ordersWithZone: OrderWithZone[]):void{
      state.ordersWithZone = uniqBy(state.ordersWithZone.concat(ordersWithZone), (order:OrderWithZone) => order._referenceNumber);
    },
    setOrdersWithZone(state:DashboardState, ordersWithZone: OrderWithZone[]):void{
      state.ordersWithZone =ordersWithZone;
    },
    setOpportunities(state:DashboardState, opportunities: Opportunity[]):void {
      state.opportunities = opportunities;
    },
    appendOpportunities(state:DashboardState, opportunities: Opportunity[]):void {
      state.opportunities = uniqBy(state.opportunities.concat(opportunities), (opportunity:Opportunity) => opportunity.id);
    },
    setVisibilityStates(state:DashboardState, record:VisibilityStateStore):void{
      Vue.set(state, 'visibilityStates', Object.assign({}, state.visibilityStates, record));
    },
    setVisibilityState(state:DashboardState, request:{ date:string, visibilityState:VisibilityState }):void{
      const index = state.visibilityStates[request.date].findIndex(vs => vs._id === request.visibilityState._id && vs._runNumber === request.visibilityState._runNumber);
      if(index < 0){
        console.log('setVisibilityState - viisbility state not found', )
        return;
      }
      const vs = state.visibilityStates[request.date][index];
      Vue.set(state.visibilityStates[request.date], index, Object.assign({}, vs, { visible: request.visibilityState.visible }));
    },
  },
  actions: {
    async persistState({ state }:DashboardContext):Promise<void>{
      window.localStorage.setItem('frozenState', JSON.stringify(state));
    },
    async syncAll({ dispatch }:DashboardContext, _date?: string):Promise<void>{
      const date = _date || moment().format(dateFormat);

      await Promise.all([
        dispatch('syncDrivers'),
        dispatch('syncPrinters'),
        dispatch('syncOrdersWithZone', date),
        dispatch('syncSchedule', date),
      ]);
      await dispatch('loadMissingOpportunities');
      await dispatch('initVisibility', date);
      await dispatch('clearOldData', date);
      await dispatch('persistState');
    },
    async refreshAllData({ dispatch }:DashboardContext, _date?: string):Promise<void>{
      const date = _date || moment().format(dateFormat);

      await Promise.all([
        dispatch('syncOrdersWithZone', date),
        dispatch('syncSchedule', date),
      ]);
      await dispatch('refreshOpportunities', date);
      await dispatch('persistState');
    },
    async clearOldData({ state, commit }:DashboardContext, date:string):Promise<void>{
      const keys = Object.keys(state.runs);
      keys
        .filter(key => key < date)
        .forEach(key => commit('deleteRun', key));

      const numbers = new Set(Object.keys(state.runs).flatMap((date:string) => {
        return extractNumbersFromRun(state.runs[date]);
      }))

      const opportunities = state.opportunities.filter(opportunity => numbers.has(opportunity.number));
      commit('setOpportunities', opportunities);
      
      const ordersWithZone = state.ordersWithZone.filter(order => {
        const endDate = MaxoptraHelper.dateToMoment(order.dropWindows.dropWindow._endTime).format(dateFormat);
        return endDate > date;
      });
      commit('setOrdersWithZone', ordersWithZone);
    },
    async syncSchedule({ state, commit }:DashboardContext, date: string):Promise<void>{
      try{
        const schedule = await gshApi.getScheduleByAOCOnDate(date);
        if(!schedule){
          return commit('setRuns', { [date]: [] });
        }
        const runs = reformatScheduleAsRuns(schedule, state.ordersWithZone);
        const runsWithStartTimes = updateRunStartTimes(runs, moment());
        // const runsWithStartTimes = runs;

        // const runsWithStartTimes = state.runs[date];
        return commit('setRuns', { [date]: runsWithStartTimes });
      }catch(e){
        console.error('syncSchedule error', e);
        return commit('setRuns', { [date]: [] });
      }
    },
    async syncOrdersWithZone({ commit }:DashboardContext, date: string):Promise<void>{
      try{
        const orders = await gshApi.getOrdersWithZone(date);
        return commit('updateOrdersWithZone', orders);
      }catch(e){
        console.error('syncOrdersWithZone error', e);
      }
    },
    async syncDrivers({ commit }:DashboardContext):Promise<void>{
      const response = await gshApi.getDriverList();
      return commit('setDrivers', response.drivers);
    },
    async syncPrinters({ commit }:DashboardContext):Promise<void>{
      const printers = await gshApi.printerList();
      return commit('setPrinters', printers);
    },
    async setSelectedPrinterId({ dispatch, commit }:DashboardContext, printerId:number):Promise<void>{
      commit('setSelectedPrinterId', printerId);
      return dispatch('persistState');
    },
    async initVisibility({ commit, state }:DashboardContext, date: string):Promise<void>{
      const visibilityStates = state.runs[date]
        .map(({ _runNumber, vehicleId }) => ({ _runNumber, _id: vehicleId, visible: true }));
      return commit('setVisibilityStates', { [date]: visibilityStates });
    },
    async loadMissingOpportunities({ commit, state }:DashboardContext):Promise<void>{
      const numbers = Object.keys(state.runs).flatMap((date:string) => {
        return extractNumbersFromRun(state.runs[date]);
      })
      const missingNumbers = numbers.reduce((missing:string[], num: string) => {
        if(state.opportunities.find((opp:Opportunity) => opp.number.toString() === num)){
          return missing;
        }
        return missing.concat([num]);
      }, []);

      if(!missingNumbers.length){
        return;
      }
      const opportunities = await gshApi.findOpportunitiesByNumbers(missingNumbers);
      return commit('appendOpportunities', opportunities);
    },
    async refreshOpportunities({ commit, state }:DashboardContext, date:string):Promise<void>{
      const numbers = extractNumbersFromRun(state.runs[date]);

      if(!numbers.length){
        return;
      }
    
      const opportunities = await gshApi.findOpportunitiesByNumbers(numbers);

      const ids = new Set(opportunities.map(opportunity => opportunity.id));
      const existingOpps = state.opportunities.filter(opportunity => !ids.has(opportunity.id));

      return commit('setOpportunities', existingOpps.concat(opportunities));
    },
    async showAllRuns({ state, commit }:DashboardContext, date: string):Promise<void>{
      const existing = state.visibilityStates[date];
      if(!existing){
        return;
      }
      const visibilityStates = existing.map(visibilityState => Object.assign({}, visibilityState, { visible: true }));
      return commit('setVisibilityStates', { [date]: visibilityStates })
    },
    async hideAllRuns({ state, commit }:DashboardContext, date: string):Promise<void>{
      const existing = state.visibilityStates[date];
      if(!existing){
        return;
      }
      const visibilityStates = existing.map(visibilityState => Object.assign({}, visibilityState, { visible: false }));
      return commit('setVisibilityStates', { [date]: visibilityStates })
    },
    async setVisibility({ state, commit }:DashboardContext, request: { date: string, visibilityState: VisibilityState }):Promise<void>{
      const existing = state.visibilityStates[request.date];
      if(!existing){
        return;
      }
      return commit('setVisibilityState', request)
    },
  },
  getters: {
    hasRunsForDate: (state:DashboardState) => (date:string):boolean => {
      return Array.isArray(state.runs[date]) && state.runs[date].length > 0;
    },
    getUnfinishedRuns: (state:DashboardState) => (date:string, searchText = ''):StructuredRun[] => {
      return unfinishedRuns(filterAndHydrate(state, date, searchText, filterVans));
    },
    getMorningRuns: (state:DashboardState) => (date:string, searchText = ''):StructuredRun[] => {
      return morningRuns(filterAndHydrate(state, date, searchText, filterVans));
    },
    getAfternoonRuns: (state:DashboardState) => (date:string, searchText = ''):StructuredRun[] => {
      return afternoonRuns(filterAndHydrate(state, date, searchText, filterVans));
    },
    getEveningRuns: (state:DashboardState) => (date:string, searchText = ''):StructuredRun[] => {
      return eveningRuns(filterAndHydrate(state, date, searchText, filterVans));
    },
    getFinishedRuns: (state:DashboardState) => (date:string, searchText = ''):StructuredRun[] => {
      return finishedRuns(filterAndHydrate(state, date, searchText, filterVans));
    },
    getOrderWithZone: (state:DashboardState) => (orderReference:string):OrderWithZone | undefined => {
      return state.ordersWithZone.find(order => order._referenceNumber === orderReference);
    },
    getWarehouseCollections: (state:DashboardState) => (date:string, searchText = ''):StructuredOrder[] => {
      return filterWarehouseAndHydrate(state, date, searchText, filterWarehouseCollections);
    },
    getWarehouseReturns: (state:DashboardState) => (date:string, searchText = ''):StructuredOrder[] => {
      return filterWarehouseAndHydrate(state, date, searchText, filterWarehouseReturns);
    },
    getVisibility: (state:DashboardState) => (date: string, runNumber:string, id:string):boolean => {
      const _record = state.visibilityStates[date];
      if(!_record){
        return true;
      }
      const record  = _record.find(({ _runNumber, _id }) => _runNumber === runNumber && _id === id);
      if(!record){
        return true;
      }
      return record.visible;
    },
    printers: (state:DashboardState):Printer[] => {
      return state.printers;
    }
  },
}