





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { range } from 'lodash';
import moment, { Moment } from 'moment';
import { OrderWithZone } from '@/lib/model';
import { OverrideError, OverridesByJob, UpdateData, VansByDate } from '@/lib/overrides-model';
import { MaxoptraHelper } from '@/lib/MaxoptraHelper';

@Component({})
export default class OverrideDetails extends Vue {
  @Prop() override!: OverridesByJob;
  @Prop() startDate!: Moment;
  @Prop() numDays!: number;
  @Prop() locked!: unknown;
  @Prop() orderData!: OrderWithZone[];

  rmsUrl:string = process.env.VUE_APP_RMS_URL as string;
  days = range(this.numDays);
  toSave = JSON.parse(JSON.stringify(this.override));
  errors:OverrideError[] = [];

  getOrder(orderReference:string):OrderWithZone|undefined{
    return this.orderData.find(order => order._referenceNumber === orderReference)
  }

  postcode(orderReference:string):string{
    const order = this.orderData.find(order => order._referenceNumber === orderReference);
    return MaxoptraHelper.partialPostcode(order?._address || 'No address')
  }
  createDate(offset:number):Moment{
    return moment(this.startDate).add(offset, 'days');
  }

  getDeliveryDataForDateOffset(offset:number):VansByDate|undefined{
    const date = moment(this.startDate).add(offset, 'days').format('DD/MM/YYYY');
    return this.override.deliveries.find(d => d.date == date);
  }
  getCollectionDataForDateOffset(offset:number):VansByDate|undefined{
    const date = moment(this.startDate).add(offset, 'days').format('DD/MM/YYYY');
    return this.override.collections.find(d => d.date == date);
  }

  getDeliveryDayData():(VansByDate|undefined)[]{
    return this.days.map(this.getDeliveryDataForDateOffset)
  }
  getCollectionDayData():(VansByDate|undefined)[]{
    return this.days.map(this.getCollectionDataForDateOffset)
  }

  updateEnabled(orderReference:string, checked:boolean):void{
    this.updateData({ enabled: checked, orderReference });
  }
  updateWeight(orderReference:string, weight:number):void{
    this.updateData({ weight, orderReference });
  }
  updateDuration(orderReference:string, duration:string):void{
    try{
      this.clearErrors(orderReference);
      this.validateDuration(orderReference, duration);
    }catch(_e){
      const e = _e as Error;
      this.errors.push({ orderReference, message: e.message });
    }
    this.updateData({ duration, orderReference });
  }
  updateData(record: Partial<UpdateData>):void{
    const { id, subject } = this.override;
    this.$emit('updateData', Object.assign({ id, subject }, record));
  }
  validateDuration(orderReference:string, duration:string):void{
    console.log(orderReference, duration, duration.match('^[0-9]{2}:[0-9]{2}$'));
    if(!duration.match('^[0-9]{2}:[0-9]{2}$')){
      throw Error('invalid duration');
    }
  }
  addError(error:OverrideError):void{
    this.errors = [error, ...this.errors];
  }
  clearErrors(orderReference:string):void{
    this.errors = [...this.errors.filter(e => e.orderReference !== orderReference)];
  }
  getErrors(orderReference:string):string|null{
    const error = this.errors.filter(e => e.orderReference === orderReference).pop();
    if(!error){
      return null;
    }
    return error.message;
  }
}

