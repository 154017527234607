import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class PrintButtonMixin extends Vue {
  
  private printed = false;
  private printTimeout:number | undefined = undefined;

  printClicked():void{
    this.printed = true;
    this.printTimeout = window.setTimeout(() => this.printed = false, 60000);
  }

  beforeDestroy():void{
    clearTimeout(this.printTimeout);
  }
}