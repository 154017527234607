












import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import WarehouseLayout from '@/components/WarehouseLayout.vue'
import { PrintManagerMixin } from '@/mixins/PrintManager';
import PrinterSelector from '@/components/PrinterSelector.vue';

@Component({
  components: {
    WarehouseLayout,
    PrinterSelector
  }
})
export default class Warehouse extends mixins(PrintManagerMixin) {}

