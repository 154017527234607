


















import { LocationTimes } from '@/mixins/LocationTimes';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class OrderTimes extends mixins(LocationTimes) {
  @Prop() private humanReadableTime!: string;

  removeWhiteSpace(str: string): string {
    return str.replace(/\s/g, '');
  }
}
