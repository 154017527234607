




import { Prop, Component, Watch, Vue } from 'vue-property-decorator';
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar, { EventInput } from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimegridPlugin from '@fullcalendar/resource-timegrid'

@Component({
  components: {
    FullCalendar // make the <FullCalendar> tag available
  }
})
export default class Calendar extends Vue{
  @Prop() events!: EventInput[];
  
  @Watch('events')
  onEventsUpdate(val: unknown): void {
    console.log('Events watch triggered!')
    this.calendarOptions = Object.assign({}, this.calendarOptions, { events: val });
  }

  protected calendarOptions = {
    timeZone: 'UTC',
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    plugins: [ dayGridPlugin, interactionPlugin, resourceTimegridPlugin ],
    initialView: 'resourceTimeGridSevenDays',
    // initialView: 'dayGridMonth'
    headerToolbar: {
      // left: 'prev,next',
      // center: 'title',
      // right: 'resourceTimeGridSevenDays'
    },
    views: {
      resourceTimeGridSevenDays: {
        type: 'resourceTimeGrid',
        duration: { days: 7 },
        // buttonText: '7 days'
      }
    },
    eventContent: (arg: { event: { title: string }}): { html: string } => {
      // console.log()
      return { html: `
      <p>${arg.event.title}</p>
      ` };
      // return { html: `
      // <p>${arg.event.title}</p>
      // <p>${JSON.stringify(arg.event.extendedProps.custom_fields)}</p>
      // ` };
    },
    resources: [
      { id: 'a', title: 'Room A' },
      // { id: 'b', title: 'Room B' }
    ],
    events: this.events,
  }
}

