import { StructuredOrder } from "./model"

export class CustomPrinter{
  public static render(order:StructuredOrder):string{
    return `<!DOCTYPE html>
  <html>
  <head>
  <style>

  </style>
  </head>
  <body>
    <div>
      <h1>${order._orderReference}</h1>
      <ol>
        <dt>Contact</dt>
        <dd>${order._contactPerson || 'Name not available'}</dd>
        <dt>Address</dt>
        <dd>${order._address}</dd>
        <dt>Arrival Time</dt>
        <dd>${order._planArrivalTime}</dd>
        <dt>Departure Time</dt>
        <dd>${order._planDepartureTime}</dd>
        <dt>Task</dt>
        <dd>${order._task}</dd>
        <dt>Weight</dt>
        <dd>${order._weight}</dd>
      </ol>
    </div>
  </body>
  </html>`
  }
}