









import { OpportunityItem } from '@/lib/model';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class OpportunityItemsView extends Vue {
  @Prop() private opportunityItems!: OpportunityItem[];
  @Prop() private showItems!: boolean;
}
