






import { Component, Prop, Vue } from 'vue-property-decorator';
import { StructuredRun } from '@/lib/model';
import RunView from './RunView.vue';

@Component({
  components: {
    RunView
  }
})
export default class RunViewGrid extends Vue {
  @Prop() runs!: StructuredRun[];
  @Prop() date!: string;
}

