

























import moment, { Moment } from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { gshApi } from "@/lib/AxiosPlugin";
import { EventInput } from '@fullcalendar/vue';
import Calendar from '@/components/Calendar.vue';
import { Opportunity } from '@/lib/model';

// export type Event = {
//   resourceId: string,
//   title: subject,
//   start: deliver_starts_at,
//   end: moment(deliver_ends_at).add(6, 'hours').toISOString(),
//   extendedProps: { custom_fields },
// }


@Component({
  components: {
    Calendar
  }
})
export default class Planning extends Vue{
  protected events: EventInput[] = [];

  async mounted():Promise<void>{
    const opps = await gshApi.getAllFutureOpportunities();
    // opps.map(({ subject, deliver_starts_at, deliver_ends_at }) => {
    //   console.log(subject, deliver_starts_at, deliver_ends_at)
    // })
    this.events = opps.flatMap(opp => {
      const { subject, custom_fields } = opp;
      const deliveryStartMoment = createDeliveryStartMoment(opp);
      const collectionStartMoment = createCollectionStartMoment(opp);
      const deliveryColor = createDeliveryColour(opp, deliveryStartMoment);
      const collectionColor = createCollectionColour(opp, collectionStartMoment);
      const delivery = {
        resourceId: 'a',
        title: 'DELIVERY '+subject,
        start: deliveryStartMoment.toISOString(),
        end: moment(deliveryStartMoment).add(2, 'hours').toISOString(),
        extendedProps: { custom_fields },
        color: deliveryColor,
      }
      const collection = {
        resourceId: 'a',
        title: 'COLLECTION '+subject,
        start: collectionStartMoment.toISOString(),
        end: moment(collectionStartMoment).add(2, 'hours').toISOString(),
        extendedProps: { custom_fields },
        color: collectionColor,
      }
      return [delivery, collection]
    })
  }
}

function createDeliveryStartMoment(opportunity: Opportunity):Moment{
  const { custom_fields, customer_collecting } = opportunity;
  if(customer_collecting){
    return moment(opportunity.deliver_starts_at)
      .hours(9)
      .minutes(0)
  }
  if(custom_fields?.delivery_time){
    const [hours, minutes] = custom_fields.delivery_time.split(':');
    return moment(opportunity.deliver_starts_at)
      .hours(parseInt(hours))
      .minutes(parseInt(minutes))
  }
  return moment(opportunity.deliver_starts_at);
  // if(custom_fields.delivery_start)
}
function createCollectionStartMoment(opportunity: Opportunity):Moment{
    const { custom_fields, customer_returning } = opportunity;
  if(customer_returning){
    return moment(opportunity.collect_starts_at)
      .hours(17)
      .minutes(0)
  }
  if(custom_fields?.collection_time){
    const [hours, minutes] = custom_fields.collection_time.split(':');
    return moment(opportunity.collect_starts_at)
      .hours(parseInt(hours))
      .minutes(parseInt(minutes))
  }
  return moment(opportunity.collect_starts_at);
  // if(custom_fields.delivery_start)
}

function createDeliveryColour(opportunity:Opportunity, start: Moment){
  if(start.toISOString() < (new Date()).toISOString()){
    return '#ccc';
  }
  return opportunity.customer_collecting ? '#00f' : '#f00';
}
function createCollectionColour(opportunity:Opportunity, start: Moment){
  if(start.toISOString() < (new Date()).toISOString()){
    return '#ccc';
  }
  return opportunity.customer_returning ? '#00f' : '#0f0';
}

