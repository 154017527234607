






import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class App extends Vue {
  
  private async syncAll():Promise<void>{
    await this.$store.dispatch('syncAll');
  }

  async mounted():Promise<void>{
    await this.syncAll();
  }
}

