












import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import MultiRunLayout from '@/components/MultiRunLayout.vue'
import { PrintManagerMixin } from '@/mixins/PrintManager';
import PrinterSelector from '@/components/PrinterSelector.vue';

@Component({
  components: {
    MultiRunLayout,
    PrinterSelector
  }
})
export default class LoadingBay extends mixins(PrintManagerMixin) {}

