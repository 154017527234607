


































import { Component, Prop } from 'vue-property-decorator';
import PickUp from './PickUp.vue';
import BackToHome from './BackToHome.vue';
import { StructuredRun } from '@/lib/model';
import OrderView from './OrderView.vue';
import { PrintMultipleOrdersMixin } from '@/mixins/PrintMultipleOrdersMixin'
import { mixins } from 'vue-class-component';
import { MaxoptraRunStatus } from '@/lib/MaxoptraHelper';

@Component({
  components: { PickUp, BackToHome, OrderView }
})
export default class RunView extends mixins(PrintMultipleOrdersMixin) {
  @Prop() private run!: StructuredRun;
  @Prop() private date!: string;

  inProgress = MaxoptraRunStatus.IN_PROGRESS;
  ended = MaxoptraRunStatus.ENDED;
  notStarted = MaxoptraRunStatus.NOT_STARTED;

  get visible():boolean{
    return this.$store.getters.getVisibility(this.date, this.run._runNumber, this.run.vehicleId);
  }
  
  setVisibility(visible:boolean):void {
    const visibilityState = {
      _runNumber: this.run._runNumber,
      _id: this.run.vehicleId,
      visible
      }
    console.log('setVisibility', 'date', this.date,  '_runNumber', this.run._runNumber,
      '_id', this.run.vehicleId,
      visible);
    this.$store.dispatch('setVisibility', { date: this.date, visibilityState });
  }

}
