import moment from "moment";
import { CurrentHelper } from "./CurrentHelper";
import { DriverManifest, DriverManifestData, DriverManifestOrder } from "./DriverManifest";
import { Gsh } from "./gsh";
import { MaxoptraHelper } from "./MaxoptraHelper";
import { StructuredRun, StructuredOrder, MaxoptraTaskTypes } from "./model";

export class ManifestHelper{
  public static runToManifest(run:StructuredRun): string{
    const data = this.runToManifestData(run);
    return DriverManifest.render(data);
  }

  private static runToManifestData(run:StructuredRun): DriverManifestData{
    //TODO: format date/time!
    const startTime = run._runStartTime;
    //TODO: format date/time!
    const endTime = run._runEndTime;
    const driveTime = moment.utc(moment.duration(run._planDuration, 'minutes').asMilliseconds()).format('HH:mm');

    const printedDateTime = moment().format("DD/MM/YYYY HH:mm");

    const orders = run.orders.map(this.runOrderToManifestOrder)

    const date = run._runDay;
    const driverName = run.driverName;
    const vanName = run.vehicleName;
    const distance = run._planDistance+'miles';
    const weight = run._planWeight+'kg';
    const notes = '';

    return {
      date,
      driverName,
      vanName,
      driveTime,
      distance,
      weight,
      startTime,
      endTime,
      printedDateTime,
      notes,
      orders
    }
  }

  private static runOrderToManifestOrder(order:StructuredOrder, index:number, allOrders:StructuredOrder[]):DriverManifestOrder{
    const nextOrder = allOrders[index+1];
    const leaveBy = nextOrder ? MaxoptraHelper.calculateLeaveBy(nextOrder) : '';

    //TODO: format date/time!
    const arrivalTime =order._planArrivalTime;

    const weight = parseInt(order._weight);
    const distance = parseInt(order._planMileage);
    
    const isRadioJob = (order.opportunity ? CurrentHelper.isRadioJob(order.opportunity) : false) && order._task === MaxoptraTaskTypes.DROP;
    
    const jobNumber = order.opportunity?.number || 'No number';

    const humanReadableTime = Gsh.humanReadableTime(order);

    return {
      index: order._number || '',
      jobNumber,
      name: order._orderReference || '',
      address: order._address || '',
      requestedTime: humanReadableTime || '',
      weight: weight,
      distance: distance,
      arrivalTime: arrivalTime,
      leaveBy,
      clientName: '',
      task: order._task,
      isRadioJob
    }
  }
}