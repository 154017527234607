import { Opportunity, OpportunityStatues } from "@/lib/model"

export class CurrentHelper {
  static opportunityStatusOpen(opportunity: Opportunity):boolean{
    return opportunity.status_name === OpportunityStatues.Open;
  }
  static opportunityStatusComplete(opportunity: Opportunity):boolean{
    return opportunity.status_name === OpportunityStatues.Completed;
  }
  static opportunityStatusActive(opportunity: Opportunity):boolean{
    return opportunity.status_name === OpportunityStatues.Active;
  }
    // isCollectionConfirmed(){
  //   return get(this, 'opp.custom_fields.collection_confirmed', 'No') === 'Yes';
  // },
  // isDeliveryConfirmed(){
  //   return get(this, 'opp.custom_fields.delivery_confirmed', 'No') === 'Yes';
  // },

  static isRadioJob(opportunity:Opportunity):boolean {
    return opportunity.opportunity_items.filter(item => 
      (item.opportunity_item_type === 0 &&
        item.name.toLowerCase().includes('radio'))
        ||
      (item.opportunity_item_type === 1 &&
        item.name.toLowerCase().includes('radio'))
    ).length > 0;
  }
}