































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import JobStatus from '@/components/JobStatus.vue';
import GMap from '@/components/GMap.vue';
import Badge from '@/components/Badge.vue';
import OpportunityItemsView from '@/components/OpportunityItemsView.vue';
import OrderViewHeading from '@/components/OrderViewHeading.vue';
import DeliveryInstructions from '@/components/DeliveryInstructions.vue';
import OrderTimes from '@/components/OrderTimes.vue'
import { PrintOrderMixin } from '@/mixins/PrintOrderMixin'
import { Gsh } from '@/lib/gsh';
import { StructuredRun, StructuredOrder, OpportunityItem } from '@/lib/model';
import { MaxoptraHelper } from '@/lib/MaxoptraHelper';
import { CurrentHelper } from '@/lib/CurrentHelper';
import '../styles/order-styles.css'

const noAddressText = 'No address found';

@Component({
  components: { GMap, Badge, JobStatus, OpportunityItemsView, OrderViewHeading, DeliveryInstructions, OrderTimes }
})
export default class OrderView extends mixins(PrintOrderMixin) {
  @Prop() protected order!: StructuredOrder;
  @Prop() protected run!: StructuredRun | undefined;
  @Prop({ default: true }) protected visible!: boolean;

  protected showItems = false;
  protected showMap = false;

  get streetAddress():string{
    return (this.order._address || noAddressText).split(',').shift() || noAddressText;
  }

  formatWeight(weight: string): number {
    return Math.ceil(parseFloat(weight));
  }

  get partialPostcode():string{
    return MaxoptraHelper.partialPostcode(this.order._address || noAddressText);
  }
  get number():string{
    return this.order.opportunity?.number || 'No number';
  }
  get isRadioJob():boolean{
    if(!this.order.opportunity) return false;
    return CurrentHelper.isRadioJob(this.order.opportunity);
  }
  
  get opportunityItems():OpportunityItem[]{
    return this.order.opportunity?.opportunity_items || [];
  }
  
  get latlng():{lat: number, lng: number} {
    return {
      lat: Number(this.order._latitude),
      lng: Number(this.order._longitude),
    }
  }

  get deliveryInstructions():string{
    return this.order.opportunity?.delivery_instructions || '';
  }

  get humanReadableTime():string{
    return Gsh.humanReadableTime(this.order);
  }
  
  get subject():string{
    return this.order.opportunity?.subject || this.order._orderReference;
  }
  
  toggleItems():void{
    this.showItems = !this.showItems;
  }
  toggleMap():void{
    this.showMap = !this.showMap;
  }
}


