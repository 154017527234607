import { StructuredRun } from '@/lib/model';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { PrintOrderMixin } from './PrintOrderMixin';
import { ToastMixin } from './ToastMixin';
import EventBus from '@/lib/EventBus';
import { PrintDataTypes, PrintHtmlData, PrintJob, PrintJobTypes, PrintOrientation, PrintVanCheckData } from './PrintManager';
import { ManifestHelper } from '@/lib/ManifestHelper';
import moment from "moment";
@Component({})
export class PrintMultipleOrdersMixin extends mixins(ToastMixin, PrintOrderMixin) {

  async printRun(run:StructuredRun):Promise<void>{
    this.makeToast('Printer', 'Starting print run');

    this.printJob(this.createManifest(run));
    this.printJob(this.vancheck(run));
    run.orders.forEach(this.printOrder);
  }

  printJob(job:PrintJob<PrintDataTypes>):void{
    EventBus.$emit('printJob', job);
  }

  createManifest(run:StructuredRun):PrintJob<PrintHtmlData>{
    const html = ManifestHelper.runToManifest(run);

    return {
      action: PrintJobTypes.HTML,
      description: `Driver Manifest for ${run.driverName}`,
      data: {
        html,
        orientation: PrintOrientation.Landscape
      }
    }
  }

  vancheck(run:StructuredRun):PrintJob<PrintVanCheckData>{
    const driver = run.driverName;
    const vehicle = run.vehicleName;
    
    const date = run._runStartTime.split(' ')?.shift() || moment().format('DD/MM/YYYY');
    const time = run._runStartTime.split(' ')?.pop() || '';

    return {
      action: PrintJobTypes.VAN_CHECK,
      description: `Van check for ${run.vehicleName}`,
      data: {
        driver,
        vehicle,
        date,
        time
      }
    }
  }
}