






import { Component, Prop, Vue } from 'vue-property-decorator';
import { StructuredRun } from '@/lib/model';
import RunViewList from './RunViewList.vue';

@Component({
  components: {
    RunViewList
  }
})
export default class RunViewGrid extends Vue {
  @Prop() runs!: StructuredRun[];
  @Prop() date!: string;

  get sortRunsByDriver(): StructuredRun[][]{
    const mapRuns = this.runs.reduce((mapRuns, run) => {
      if(mapRuns.has(run.driverName)){
        mapRuns.get(run.driverName)?.push(run);
      } else {
        mapRuns.set(run.driverName, [run]);
      }
      return mapRuns
    }, new Map<string, StructuredRun[]>());

    return Array.from(mapRuns.values())
  }
}

