




import { MaxoptraTaskTypes, StructuredOrder, StructuredRun } from '@/lib/model';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { CurrentHelper } from '@/lib/CurrentHelper';
import { MaxoptraHelper, MaxoptraRunStatus } from '@/lib/MaxoptraHelper';

export type BadgeDefinition = {
  [key:string]: boolean
}

@Component({
  components: { }
})
export default class Badge extends Vue {
  @Prop() private run!: StructuredRun | undefined;
  @Prop() private order!: StructuredOrder;
  @Prop() private isRadioJob!: boolean;

  runStatus = '';
  dropType = MaxoptraTaskTypes.DROP;

  get badgeClasses():BadgeDefinition{
    if(!this.run){
      this.runStatus = 'No information';
       if(MaxoptraHelper.isDelivery(this.order)){
        return {'plain-badge-delivery': true}
       }else{
        return {'plain-badge-collection': true}
       }
    }
    switch(this.run.runStatus){
      case MaxoptraRunStatus.ENDED:
        return this.runEndedBadge()
      case MaxoptraRunStatus.IN_PROGRESS:
        return this.runInProgressBadge()
      default: //not started
        return this.runNotStatedBadge()
    }
  }

  opportunityStatusComplete():boolean{
    return !!this.order.opportunity && CurrentHelper.opportunityStatusComplete(this.order.opportunity)
  }
  opportunityStatusOpen():boolean{
    return !!this.order.opportunity && CurrentHelper.opportunityStatusOpen(this.order.opportunity)
  }
  opportunityStatusActive():boolean{
    return !!this.order.opportunity && CurrentHelper.opportunityStatusActive(this.order.opportunity)
  }

  runEndedBadge():BadgeDefinition{
    if(!MaxoptraHelper.isDelivery(this.order)){
      //collection
      if(this.opportunityStatusComplete() || !this.order.opportunity){
        //completed, nothing to do
        this.runStatus = 'completed, nothing to do';
        return this.getBadge(false, false)
      }
      //active, needs unloading
      this.runStatus = 'active, needs unloading';
      return this.getBadge(false, true)
    }
    //delivery
    if(!MaxoptraHelper.jobRejected(this.order)){
      //job ok, nothing to do
      this.runStatus = 'job ok, nothing to do';
      return this.getBadge(true, false)
    }
    if(this.opportunityStatusOpen()){
      this.runStatus = 'failed and active, needs unloading';
      //failed and active, needs unloading
      return this.getBadge(true, true)
    }
    //rejected, but not active, nothing to do
    this.runStatus = 'rejected, but not active, nothing to do';
    return this.getBadge(true, false)
  }
  runInProgressBadge():BadgeDefinition{
    if(MaxoptraHelper.jobComplete(this.order)){
      if(MaxoptraHelper.isDelivery(this.order)){
        //delivery, complete, nothing to do
        this.runStatus = 'delivery, complete, nothing to do';
        return this.getBadge(true, false)
      }
      //collection, complete, will need unloading
      this.runStatus = 'collection, complete, will need unloading';
      return this.getBadge(false, true)
    }
    if(MaxoptraHelper.jobRejected(this.order)){
      if(MaxoptraHelper.isDelivery(this.order)){
        //delivery, rejected, will need unloading
        this.runStatus = 'delivery, rejected, will need unloading';
        return this.getBadge(true, true)
      }
      //collection, rejected, nothing to do
      this.runStatus = 'collection, rejected, nothing to do';
      return this.getBadge(false, false)
    }
    if(MaxoptraHelper.isDelivery(this.order)){
      //delivery, on route, stuff on van
      this.runStatus = 'delivery, on route, goods on van';
      return this.getBadge(true, true)
    }
    //collection, on route, not on van yet
    this.runStatus = 'collection, on route, not on van yet';
    return this.getBadge(false, false)
  }

  runNotStatedBadge():BadgeDefinition{
    if(!MaxoptraHelper.isDelivery(this.order)){
      //collection, not started, nothing on van
      this.runStatus = 'collection, not started, nothing on van';
      return this.getBadge(false, false);
    }
    //delivery
    if(this.opportunityStatusActive()){
      //delivery, not started, on van
      this.runStatus = 'delivery, not started, on van';
      return this.getBadge(true, true);
    }
    //delivery, not started, not on van
    this.runStatus = 'delivery, not started, not on van';
    return this.getBadge(true, false);
  }

  getBadge(isDelivery: boolean, isFilled: boolean):BadgeDefinition{
    const deliveryPart = isDelivery ? 'delivery' : 'collection'
    const filledPart = isFilled ? 'filled' : 'blank'

    return {
      [`${deliveryPart}-badge-${filledPart}`]: true
    }
  }
}
