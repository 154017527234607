import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import router from './router'
import { store } from '@/lib/store'
import * as VueGoogleMaps from 'gmap-vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import { NavigationGuardNext, Route } from 'vue-router';

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuex);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    // libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
  },
  installComponents: true
})

Vue.config.productionTip = false;

// router.beforeEach((to:Route, from:Route, next: NavigationGuardNext) => {

  // console.log(to.fullPath, store.state.auth.token);

  // if(to.fullPath !== '/login' && !store.state.auth.loggedIn){
  //   return next('/login');
  // }
  // if(to.fullPath === '/login' && store.state.auth.loggedIn){
  //   return next('/');
  // }
//   next();
// })

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app');
