import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import LoadingBay from '../views/LoadingBay.vue'
import Warehouse from '../views/Warehouse.vue'
import Update from '../views/Update.vue'
import Overrides from '../views/Overrides.vue'
import Planning from '../views/Planning.vue'
// import Login from '../views/Login.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'LoadingBay', component: LoadingBay },
  { path: '/warehouse', name: 'Warehouse', component: Warehouse },
  { path: '/update', name: 'Update', component: Update },
  { path: '/overrides', name: 'Overrides', component: Overrides },
  { path: '/planning', name: 'Planning', component: Planning },
  // { path: '/login', name: 'Login', component: Login },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
