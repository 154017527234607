












































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { SyncMixin } from '@/mixins/SyncMixin'
import { SearchMixin } from '@/mixins/SearchMixin'
import {StructuredRun} from '@/lib/model';
import RunViewGrid from '@/components/RunViewGrid.vue';
import '../styles/schedule-styles.css';

@Component({
  components: {
    RunViewGrid,
  }
})
export default class MultiRunLayout extends mixins(SyncMixin, SearchMixin) {
  protected searchTerm = '';

  get unfinishedRuns(): StructuredRun[]{
    return this.$store.getters.getUnfinishedRuns(this.formattedDate, this.searchTerm);
  }
  get morningRuns(): StructuredRun[]{
    return this.$store.getters.getMorningRuns(this.formattedDate, this.searchTerm);
  }
  get afternoonRuns(): StructuredRun[]{
    return this.$store.getters.getAfternoonRuns(this.formattedDate, this.searchTerm);
  }
  get eveningRuns(): StructuredRun[]{
    return this.$store.getters.getEveningRuns(this.formattedDate, this.searchTerm);
  }
  get finishedRuns(): StructuredRun[]{
    return this.$store.getters.getFinishedRuns(this.formattedDate, this.searchTerm);
  }

  async showAll():Promise<void>{
    await this.$store.dispatch('showAllRuns', this.formattedDate);
  }
  async hideAll():Promise<void>{
    await this.$store.dispatch('hideAllRuns', this.formattedDate);
  }
}

