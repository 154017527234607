import { KeyValuePair } from "@/lib/model";

export function createKeyPair(str:string): KeyValuePair | undefined{
  const kvPair = str.split(':');
    if(kvPair.length !== 2) return undefined;
    const [ key, value ] = kvPair;
    return { key, value }
}
export function splitLines(str:string):string[] {
  return str.split('\n');
}