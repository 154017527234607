import { Component, Prop, Vue } from 'vue-property-decorator';
import { BaseLocation } from '@/lib/model';
import { MaxoptraHelper } from '@/lib/MaxoptraHelper';

@Component({
  components: { }
})
export class LocationTimes extends Vue {
  @Prop() protected location!: BaseLocation;

  protected factArrivalTime = MaxoptraHelper.extractTime(this.location._factArrivalTime);
  protected estimatedArrivalTime = MaxoptraHelper.extractTime(this.location._estimatedArrivalTime);
  protected planArrivalTime = MaxoptraHelper.extractTime(this.location._planArrivalTime) || '';
  
  protected arrivalTime = this.estimatedArrivalTime || this.planArrivalTime;
  protected arrivedLate = this.factArrivalTime > this.arrivalTime;
  protected arrivingLate = this.estimatedArrivalTime > this.arrivalTime;
}