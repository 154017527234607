















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: { }
})
export default class GMap extends Vue {
  @Prop() private latlng!: {lat:number, lng:number};
}
