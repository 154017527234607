import { MaxoptraTaskTypes, StructuredOrder } from '@/lib/model';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import EventBus from '@/lib/EventBus';
import { CurrentDocType, PrintHtmlData, PrintJob, PrintJobTypes, PrintOneData, PrintOrientation } from './PrintManager';
import { CurrentHelper } from '@/lib/CurrentHelper';
import { CustomPrinter } from '@/lib/CustomerPrinter';
import { PrintButtonMixin } from './PrintButtonMixin';

type DocMapType = { [key in MaxoptraTaskTypes]: CurrentDocType }
const docMap: DocMapType = {
  [MaxoptraTaskTypes.DROP]: CurrentDocType.DELIVERY_NOTE,
  [MaxoptraTaskTypes.COLLECTION]: CurrentDocType.COLLECTION_NOTE
}

@Component({})
export class PrintOrderMixin extends mixins(PrintButtonMixin) {

  printOrder(order:StructuredOrder):void{
    this.printClicked();
    if(!order.opportunity){
      const job = PrintOrderMixin.createCustomPrintJob(order);
      EventBus.$emit('printJob', job);
      return;
    }
    const docType = PrintOrderMixin.makeDocType(order);
    const job = PrintOrderMixin.createCurrentPrintJob(order.opportunity?.id, `${order.opportunity?.subject} - ${docType}`, PrintOrderMixin.makeDocType(order));
    EventBus.$emit('printJob', job);

    if(CurrentHelper.isRadioJob(order.opportunity) && order._task === MaxoptraTaskTypes.DROP){
      const radioJob = PrintOrderMixin.createCurrentPrintJob(order.opportunity?.id, `${order.opportunity?.subject} - ${CurrentDocType.RADIO_LIST}`, CurrentDocType.RADIO_LIST);
      EventBus.$emit('printJob', radioJob);
    }
  }

  private static createCurrentPrintJob(oppId: number, description:string, type: CurrentDocType):PrintJob<PrintOneData>{
    const data = {
      oppId,
      type,
    }
    return PrintOrderMixin.createPrintJob(PrintJobTypes.CURRENT_DOC_BY_ID, description, data);
  }
  
  private static createCustomPrintJob(order:StructuredOrder):PrintJob<PrintHtmlData>{
    const data = {
      html: CustomPrinter.render(order),
      orientation: PrintOrientation.Portrait,
    }
    return PrintOrderMixin.createPrintJob(PrintJobTypes.HTML, order._orderReference, data);
  }

  private static createPrintJob<T>(action: PrintJobTypes, description: string, data: T){
    return { action, description, data }
  }

  private static makeDocType(order:StructuredOrder){
    return docMap[order._task] || CurrentDocType.ROCK_N_ROLL;
  }
}
