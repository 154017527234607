import { Credentials, Driver, Opportunity, OrderWithZone, PostWebhookRequest, Printer, 
  QueueResult, Schedule, TokenResponse, UpdateDeleteResult, VersionResponse, WebhookStatus } from '@/lib/model';
import { OverrideResponse, SaveOverrideRequest } from '@/lib/overrides-model';

import { PrintOrientation } from '@/mixins/PrintManager';
import { AxiosInstance, AxiosResponse } from 'axios';
import { pick } from 'lodash';

export class GshApi{
  
  constructor(private gshAxios: AxiosInstance){}

  async createUpdate():Promise<unknown> {
    return (await this.gshAxios.post('/update/job')).data
  }
  async submitReport(description:string, state:string):Promise<unknown> {
    return (await this.gshAxios.post('/reporter', { description, state })).data
  }
  async syncStatus():Promise<string>{
    return (await this.gshAxios.get('/update/sync/status')).data
  }
  async enableSync():Promise<unknown>{
    return (await this.gshAxios.get('/update/sync/enable')).data
  }
  async disableSync():Promise<unknown>{
    return (await this.gshAxios.get('/update/sync/disable')).data
  }
  async enableWebhooks():Promise<unknown>{
    return (await this.gshAxios.get('/update/webhooks/enable')).data
  }
  async disableWebhooks():Promise<unknown>{
    return (await this.gshAxios.get('/update/webhooks/disable')).data
  }
  async webhooksStatus():Promise<WebhookStatus[]>{
    return (await this.gshAxios.get('/update/webhooks/status')).data
  }
  async postWebhook(request:PostWebhookRequest):Promise<unknown>{
    return (await this.gshAxios.post('/update/webhook', request)).data
  }
  async getQueueStatus():Promise<QueueResult>{
    return (await this.gshAxios.get(`/update/queues`)).data;
  }
  async getLastResult():Promise<UpdateDeleteResult>{
    return (await this.gshAxios.get(`/update/result`)).data;
  }
  async getUpdate(id:string):Promise<unknown>{
    return (await this.gshAxios.get(`/update/job/${id}`)).data;
  }
  async getDriverList():Promise<{ drivers: Driver[] }>{
    return (await this.gshAxios.get('/maxoptra/drivers')).data;
  }
  async getScheduleByAOCOnDate(date:string):Promise<Schedule | undefined>{
    const response = (await this.gshAxios.get<Schedule>(`/maxoptra/getScheduleByAOCOnDate/${date}`)).data;
    if(!response || !response.vehicle){
      return undefined;
    }
    return response;
  }
  async getOrdersWithZone(date:string):Promise<OrderWithZone[]>{
    const response = (await this.gshAxios.get<OrderWithZone[]>(`/maxoptra/getOrdersWithZone/${date}`)).data;
    if(!Array.isArray(response)){
      return [];
    }
    return response;
  }
  async getAllFutureOpportunities():Promise<Opportunity[]>{
    return (await this.gshAxios.get('/current/allFutureOpportunities')).data;
  }
  async getTodaysOpportunities():Promise<Opportunity[]>{
    return (await this.gshAxios.get('/current/getTodaysOpportunities')).data;
  }
  async findOpportunitiesByNumbers(numbers:number[]|string[]):Promise<Opportunity[]>{
    const opportunities:Opportunity[] = (await this.gshAxios.get(`/current/opportunities?numbers=${numbers.join(',')}`)).data;
    return opportunities
      .map(opportunity => pick(opportunity, ['id', 'subject', 'customer_collecting', 'customer_returning', 'deliver_starts_at',
      'deliver_ends_at', 'collect_starts_at', 'collect_ends_at', 'number', 'custom_fields', 'opportunity_items',
      'status_name', 'delivery_instructions']))
      .map(opportunity => Object.assign({}, opportunity, { 
        opportunity_items: opportunity.opportunity_items
          .map(item => pick(item, ['opportunity_item_type', 'name', 'quantity']))
          .filter(item => (item.opportunity_item_type || 0) == 1)
      }));
  }
  async printerList():Promise<Printer[]>{
    return (await this.gshAxios.get('/print/printers')).data;
  }
  async version():Promise<VersionResponse>{
    return (await this.gshAxios.get('/version')).data;
  }

  async printOne(oppId: number, type:string, printerId: number):Promise<unknown>{
    const url = `/print/${oppId}/${type}?printerId=${printerId}`
    if (process.env.VUE_APP_PRINT_TEST_MODE === 'true') {
      alert(`PRINT TEST MODE, ${oppId}, ${type}, ${printerId}`)
      return Promise.resolve('Test print')
    }
    return (await this.gshAxios.get(url)).data;
  }

  async printByNumber(oppNumber:string, type:string, printerId:number):Promise<unknown>{
    const url = `/print/by-number/${oppNumber}/${type}?printerId=${printerId}`
    if (process.env.VUE_APP_PRINT_TEST_MODE === 'true') {
      alert(`PRINT TEST MODE NUMBER, ${oppNumber}, ${type}, ${printerId}`)
      return Promise.resolve('Test print')
    }
    return (await this.gshAxios.get(url)).data;
  }

  async printHtml(html:string, orientation:PrintOrientation, printerId:number):Promise<unknown> {
    const url = `/print/html?orientation=${orientation}&printerId=${printerId}`
    if (process.env.VUE_APP_PRINT_TEST_MODE === 'true') {
      alert(`PRINT TEST MODE HTML, ${html}`)
      return 'Test print'
    }
    return (await this.gshAxios.post(url, { html })).data;
  }

  async printVanCheck(data = {}, printerId:number):Promise<unknown>{
    const url = this.appendVanValuesToUrl(`/print/van-check?printerId=${printerId}`, data);

    if (process.env.VUE_APP_PRINT_TEST_MODE === 'true') {
      console.log('PRINT TEST MODE VANCHECK')
      return 'Test print'
    }
    return (await this.gshAxios.get(url)).data;
  }

  public async getOverrides():Promise<OverrideResponse[]>{
    return (await this.gshAxios.get('/overrides/merged')).data;
  }

  public async saveOverrides(overrides:SaveOverrideRequest[]):Promise<unknown>{
    return (await this.gshAxios.post('/overrides', overrides)).data;
  }

  public async authenticate(username: string, password: string):Promise<TokenResponse>{
    return (await this.gshAxios.post<Credentials, AxiosResponse<TokenResponse>>('/auth', { username, password })).data;
  }

  private appendVanValuesToUrl = (url:string, data:{ [key:string]: string }) =>
    Object
      .keys(data)
      .reduce((url:string, key:string) => this.appendKeyToUrl(key, data[key], url), url)

  private appendKeyToUrl = (key:string, value:string, url:string) => `${url}&${key}=${value}`;


  // private static async suppressError<A>(func: ()=>A, defaultResponse:A):Promise<A>{
  //   try {
  //     return await func()
  //   } catch (_e) {
  //     const e:Error = _e as Error;
  //     console.error('Axios error', e.message);
  //     return defaultResponse;
  //   }
  // }

  // private async validateDriver(driver:Driver){
  //   return driverSchema.validateAsync(driver)
  // }
}
