import { GshApi } from '@/lib/GshApi';
import { store } from './store';
import jwtHelper from '@/lib/JwtHelper'
import axios from 'axios';
import { GshAuthApi } from './GshAuthApi';

const baseURL = process.env.VUE_APP_ENDPOINT;
const apiKey = process.env.VUE_APP_API_KEY as string
const access_token = store.getters.token 

const objAxios = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    }
})

objAxios.interceptors.request.use( async (config) => {
    const token = store?.getters.token
    if(token){
        await checkTokenRenewalStatus(token)
    }
    return config;
  }, function (error) {
    return Promise.reject(error);
});

objAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
          await store.dispatch("doLogout");
      }
      throw error;
    }
  );

async function checkTokenRenewalStatus(token: string){
    const blnShouldRenewToken = jwtHelper.isTokenRenewalRequired(token)
    if(blnShouldRenewToken){
        const refresh_token = store?.getters.refresh_token
        const objAuthAxios = axios.create({
            baseURL: baseURL,
            headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey
            }
        })

        const gshAuthApi = new GshAuthApi(objAuthAxios)
        const tokens = await gshAuthApi.refreshTokens(refresh_token)
        await store?.dispatch('setTokens', tokens)
    }
}

export const gshApi = new GshApi(objAxios);
