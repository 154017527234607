












import { Printer } from '@/lib/model';
import { Component, Vue } from 'vue-property-decorator';

type SelectOption = {
  value: number | null,
  text: string
}

@Component
export default class PrinterSelector extends Vue {
  defaultOption:SelectOption = { value: null, text: 'Please select an option...' };

  selectedPrinter: number|null = this.$store.state.dashboard.selectedPrinterId || null;

  get printerNames():SelectOption[]{
    const printers: Printer[] = this.$store.state.dashboard.printers;

    return [this.defaultOption,
      ...printers.map(printer => ({
        value: printer.id,
        text: `${printer.computer.name} - ${printer.name} (${printer.state})`,
      }))
    ]
  }

  updatePrinter(printerId:number|null):void{
    if(printerId){
      this.$store.dispatch('setSelectedPrinterId', printerId);
    }
  }
}
