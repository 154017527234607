















import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { PrintButtonMixin } from '@/mixins/PrintButtonMixin';
import { MaxoptraHelper } from '@/lib/MaxoptraHelper';
import moment from 'moment-timezone';

type Classes = {
  [key:string]: boolean;
}

@Component({})
export default class PickUp extends mixins(PrintButtonMixin) {
  @Prop() private startTime!: string;

  setVisibility(visible:boolean):void{
    this.$emit('setVisibility', visible);
  }

  get formattedStartTime():string{
    return MaxoptraHelper.dateToMoment(this.startTime).format('HH:mm');
  }
  get classes():Classes{
    const startTime = MaxoptraHelper.dateToMoment(this.startTime);
    if(moment().isAfter(startTime)){
      return {}
    }
    if(startTime.subtract(30, 'minutes').isBefore(moment())){
      return { 'thirty-minutes': true };
    }
    if(startTime.subtract(60, 'minutes').isBefore(moment())){
      return { 'one-hour': true } ;
    }
    return {}
  }

  printRun():void{
    this.printClicked();
    this.$emit('printRun');
  }
}
