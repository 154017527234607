export type DriverManifestOrder = {
  index: string,
  jobNumber: string,
  name: string,
  address: string,
  requestedTime: string,
  arrivalTime: string,
  leaveBy: string,
  weight: number,
  distance: number,
  clientName: string,
  task: string,
  isRadioJob: boolean,
}

export type DriverManifestData = {
  date: string,
  driverName: string,
  vanName: string,
  driveTime: string,
  distance: string,
  weight: string,
  startTime: string,
  endTime: string,
  notes: string,
  orders: DriverManifestOrder[]
  printedDateTime: string
}


export class DriverManifest{
  static sampleManifest():DriverManifestData{
    return {
      date:'01/01/2970',
      driverName: 'Bogdan',
      vanName: 'Katie',
      driveTime: '02:05',
      distance: '19 miles',
      weight: '167 kg',
      startTime: '07:05',
      endTime: '14:30',
      notes: 'Notes here',
      printedDateTime: 'About now?',
      orders: [
        {
          index: '1',
          jobNumber: '14031d',
          name: '14031d',
          address: '14031d',
          requestedTime: '14031d',
          arrivalTime: '7:15',
          leaveBy: '7:15',
          weight: 100,
          distance: 20,
          clientName: '',
          task: 'DROP',
          isRadioJob: false
        }
      ]
    }
  }

  static render(manifest: DriverManifestData):string{
    const locationsAsTableRows = manifest.orders.map(this.renderOrder).join('')

    return `<!DOCTYPE html>
  <html>
  <head>
  <style>
  th, td{
    font-size: 1em;
  }
  .opportunities th{
    font-size: 0.8em;
  }
  .opportunities{
    width: 100%;
    border-collapse: collapse;
  }
  .opportunities tr:nth-child(odd) {
      background-color: #eee;
  }
  .opportunities td, .opportunities th{
      padding: 0.5em 0.25em;
      text-align: center;
  }
  .opportunities tr:first-child {
      background-color: #ccc;
  }
  .opportunities td{
    border: 1px solid #ccc;
  }
  h1{
    background-color: #ccc;
    padding: 0.4em 0.25em;
    font-size: 1.4em;
  }
  h2{
    font-size: 1.2em;
  }
  h1 .vanName{
    float: right;
  }
  body{
    margin: 1em;
    font-family: Arial, Helvetica, sans-serif;
  }
  table{
    margin: 2em 0;
  }
  .manifest-heading{
    text-align: center;
  }
  th.name{
    width:8em;
  }
  th.signature{
    width:16em;
  }
  span.drive-time, span.distance, span.print-time{
    padding-right: 3em;
  }
  span.print-time{
    float: right;
  }
  p.task{
    font-size: 0.8em;
  }
  </style>
  </head>
  <body>
    <div>
      <p class="manifest-heading">Driver Manifest ${manifest.date}</p>
      <h1 class="driverName">${manifest.driverName}<span class="vanName">${manifest.vanName}</span></h1>
      <div>
        <span class="drive-time">${manifest.driveTime}</span>
        <span class="distance">${manifest.distance}</span>
        <span class="weight">${manifest.weight}</span>
        <span class="print-time">Printed: ${manifest.printedDateTime}</span>
      </div>
      <table class="opportunities">
        <tr>
          <th>#</th>
          <th>Number</th>
          <th>Name</th>
          <th>Address</th>
          <th>Weight / Dist</th>
          <th>Requested<br />Time</th>
          <th>Arrive By</th>
          <th>Leave By</th>
          <th class="name">Name</th>
          <th class="signature">Signature</th>
        </tr>
        <tr>
          <td colspan="6">&nbsp;</td>
          <td></td>
          <td>${manifest.startTime}</td>
          <td></td>
          <td></td>
        </tr>
        ${locationsAsTableRows}
        <tr>
          <td colspan="6">&nbsp;</td>
          <td>${manifest.endTime}</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <h2>Notes</h2>
      <p>${manifest.notes}</p>
    </div>
  </body>
  </html>`
  }

  static renderOrder(order:DriverManifestOrder):string{
    const address = order.index ? order.address : ''
    return `<tr>
      <td>${order.index}</td>
      <td>${order.jobNumber}<p class="task">${order.task == 'DROP' ? 'Del' : 'Coll'}${order.isRadioJob ? ' (R)' : ''}</p></td>
      <td>${order.name}</td>
      <td>${address}</td>
      <td>${order.weight} / ${order.distance}</td>
      <td class="time-range">${order.requestedTime}</td>
      <td>${order.arrivalTime}</td>
      <td>${order.leaveBy}</td>
      <td style="width: 4em">${order.clientName}</td>
      <td style="width: 6em"></td>
    </tr>`
  }
}