import jwt, { JwtPayload } from 'jsonwebtoken';

const jwtHelper = {
    isTokenRenewalRequired: (token:string):boolean => {

        const jwtDetail =   jwt.decode(token)as JwtPayload;
        const renewalThreshold = process.env.VUE_APP_JWT_RENEWAL_THRESHOLD_DAYS as string;
        const renewalThresholdSec = parseInt(renewalThreshold)*24*60*60;
        
        const jwtExp = jwtDetail.exp as number;
        const currentTimestamp = Math.floor(Date.now() / 1000)
        if(currentTimestamp > jwtExp)
            return false
            
        return (jwtExp-renewalThresholdSec < currentTimestamp) && (currentTimestamp < jwtExp)
    }
}

export default jwtHelper;
