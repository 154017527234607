






















import { Component, Vue } from 'vue-property-decorator';
import { gshApi } from "@/lib/AxiosPlugin";
@Component({
  components: {},
})
export default class Login extends Vue {

  username = '';
  password = '';

  get error():string{
    return this.$store.state.auth.error;
  }

  async onSubmit(event:MouseEvent):Promise<void>{
    event.preventDefault()
    console.log(this.username, this.password);
    const username = this.username;
    const password  = this.password;
    const tokens = await gshApi.authenticate(username, password);
    this.$store?.dispatch('setTokens', tokens)
      .then(()=> 
        this.$router.go(0)
        );
  }
}
