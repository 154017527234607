import EventBus from '@/lib/EventBus';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { ToastMixin } from './ToastMixin';
import { gshApi } from "@/lib/AxiosPlugin";

export enum PrintJobTypes {
  VAN_CHECK = 'vanCheck',
  HTML = 'html',
  CURRENT_DOC_BY_NUMBER = 'currentDocByNumber',
  CURRENT_DOC_BY_ID = 'currentDocById',
}

export enum CurrentDocType {
  RADIO_LIST = 'radio-list',
  DELIVERY_NOTE = 'delivery-note',
  COLLECTION_NOTE = 'collection-note',
  ROCK_N_ROLL = 'rock-n-roll',
}

export type PrintJob<T> = {
  action: PrintJobTypes,
  description: string,
  data: T,
}

export enum PrintOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
}


export type PrintOneData = {
  oppId: number,
  type:string,
}

export type PrintByNumberData = {
  oppNumber:string,
  type:string,
}

export type PrintHtmlData = {
  html:string,
  orientation:PrintOrientation,
}

export type PrintVanCheckData = {
  driver:string,
  date:string,
  time:string,
  vehicle:string,
}

export type PrintDataTypes = PrintOneData | PrintByNumberData | PrintHtmlData | PrintVanCheckData;

@Component({})
export class PrintManagerMixin extends mixins(ToastMixin) {

  isPrinting = false;
  printJobs:PrintJob<PrintDataTypes>[] = [];
  interval:number|undefined;
  
  actions = {
    [PrintJobTypes.VAN_CHECK]: 
      (data:PrintDataTypes, printerId:number):Promise<unknown> =>
        gshApi.printVanCheck(data, printerId),
    [PrintJobTypes.HTML]:
      (data:PrintDataTypes, printerId:number):Promise<unknown> =>
        gshApi.printHtml((data as PrintHtmlData).html, (data as PrintHtmlData).orientation, printerId),
    [PrintJobTypes.CURRENT_DOC_BY_NUMBER]:
      (data:PrintDataTypes, printerId:number):Promise<unknown> =>
        gshApi.printByNumber((data as PrintByNumberData).oppNumber, (data as PrintByNumberData).type, printerId),
    [PrintJobTypes.CURRENT_DOC_BY_ID]:
      (data:PrintDataTypes, printerId:number):Promise<unknown> =>
        gshApi.printOne((data as PrintOneData).oppId, (data as PrintOneData).type, printerId),
  }

  // actions = {
  //   [PrintJobTypes.VAN_CHECK]: 
  //     (data:PrintDataTypes, printerId:number):Promise<unknown> =>
  //       Promise.resolve(console.log('gshApi.printVanCheck(', data, printerId)),
  //   [PrintJobTypes.HTML]:
  //     (data:PrintDataTypes, printerId:number):Promise<unknown> =>
  //       Promise.resolve(console.log('gshApi.printHtml(', (data as PrintHtmlData).html, (data as PrintHtmlData).orientation, printerId)),
  //   [PrintJobTypes.CURRENT_DOC_BY_NUMBER]:
  //     (data:PrintDataTypes, printerId:number):Promise<unknown> =>
  //       Promise.resolve(console.log('gshApi.printByNumber(', (data as PrintByNumberData).oppNumber, (data as PrintByNumberData).type, printerId)),
  //   [PrintJobTypes.CURRENT_DOC_BY_ID]:
  //     (data:PrintDataTypes, printerId:number):Promise<unknown> =>
  //       Promise.resolve(console.log('gshApi.printOne(', (data as PrintOneData).oppId, (data as PrintOneData).type, printerId)),
  // }


  addPrintJob(job:PrintJob<PrintDataTypes>):void{
    this.printJobs.push(job);
    // this.startPrinting();
  }

  async processPrintJob():Promise<void>{
    const job = this.printJobs.shift();
    if(!job){
      return;
    }
    const printerId = this.$store.state.dashboard.selectedPrinterId;
    await this.actions[job.action](job.data, printerId);
    this.makeToast('Printer', job.description);
  }

  async checkPrintQueue():Promise<void>{
    if(this.printJobs.length){
      await this.processPrintJob();
    }
  }

  mounted():void{
    EventBus.$on('printJob', (job:PrintJob<PrintDataTypes>) => {
      this.addPrintJob(job);
    })
    this.interval = window.setInterval(this.checkPrintQueue, 2000);
  }

  beforeDestroy():void{
    EventBus.$off('printJob');
    clearInterval(this.interval);
  }
}
