











































import { gshApi } from "@/lib/AxiosPlugin";
import {
  CurrentUpdateAction,
  GroupResult,
  QueueResult,
  Result,
  UpdateDeleteResult,
  UpdateError,
  UpdateWarning,
  WebhookStatus,
} from "@/lib/model";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

type UpdateType = {
  action: string;
} & Result;

@Component({
  components: {},
})
export default class Update extends Vue {
  interval: number | undefined;
  queueData: QueueResult = {
    jobs: [],
    nextTime: null,
  };
  lastResult:UpdateDeleteResult | null = null;
  rmsUrl: string = process.env.VUE_APP_RMS_URL as string;
  syncStatus: string | null = null;
  webhooksStatus: WebhookStatus[] = [];
  numbers = "";
  forceAddStatus = "";
  syncingForceUpdate = false;
  
  resultFields = [
    { key: 'orderReference' },
    { key: 'status' },
    { key: 'action' },
    { key: 'warnings-errors', label: 'Warnings/Errors' },
  ]

  jobFields = [
    { key: 'name' },
    { key: 'action', label: 'Action' },
  ]

  get result(): UpdateType[] {
    if (!this.lastResult) {
      console.log("there was no last result...");
      return [];
    }
    const updateResult = this.extractResults(this.lastResult.updateResult, "update");
    const deleteResult = this.extractResults(this.lastResult.deleteResult, "delete");
    return updateResult.concat(deleteResult);
  }
  get updateTime(): string {
    const { nextTime } = this.queueData;
    if (!nextTime) {
      return "";
    }
    const duration = moment.duration(moment(nextTime).diff(moment()));
    return `${duration.minutes()} min ${duration.seconds()} secs`;
  }

  formatErrors(errors: { error: UpdateError[] } | undefined):string{
    if(!errors){
      return '';
    }
    return errors.error.map(error => error.errorMessage).join('\n');
  }
  formatWarnings(warnings: { warning: UpdateWarning[] } | undefined):string{
    if(!warnings){
      return '';
    }
    return warnings.warning.map(warning => warning.warningMessage).join('\n');
  }
  extractResults(groupResult: GroupResult, action: string): UpdateType[] {
    if (!groupResult.result) {
      return [];
    }
    return groupResult.result.map((rec) => Object.assign({}, rec, { action }));
  }

  async addNumbers(): Promise<void> {
    const numbers = this.numbers.split("\n");
    await this.addOppsByNumbers(numbers);
  }
  clearBox(): void {
    this.numbers = "";
  }
  async addOppsByNumbers(numbers: string[]): Promise<void> {
    this.syncingForceUpdate = true;
    this.forceAddStatus = "";
    const opps = await gshApi.findOpportunitiesByNumbers(numbers);
    await Promise.all(
      opps.map(async (opp) => {
        const { id: subject_id, subject: name } = opp;

        const action = {
          subject_id,
          action_type: CurrentUpdateAction.Update,
          name,
          subject: {
            state: 3,
          },
        };
        try {
          await gshApi.postWebhook({ action });
          this.forceAddStatus = "Done.";
        } catch (e) {
          console.error(e);
          this.forceAddStatus = "Failed";
        }
        this.syncingForceUpdate = false;
      })
    );
  }
  async checkUpdate(): Promise<void> {
    this.queueData = await gshApi.getQueueStatus();
    this.lastResult = await gshApi.getLastResult();
  }

  enableRefresh(): void {
    this.interval = window.setInterval(this.checkUpdate, 3000);
  }

  disableRefresh(): void {
    clearInterval(this.interval);
  }

  async enableWebhooks(): Promise<void> {
    await gshApi.enableWebhooks();
    this.webhooksStatus = await gshApi.webhooksStatus();
  }

  async enableSync(): Promise<void> {
    await gshApi.enableSync();
    this.syncStatus = await gshApi.syncStatus();
  }

  async disableWebhooks(): Promise<void> {
    await gshApi.disableWebhooks();
    this.webhooksStatus = await gshApi.webhooksStatus();
  }

  async disableSync(): Promise<void> {
    await gshApi.disableSync();
    this.syncStatus = await gshApi.syncStatus();
  }

  async mounted(): Promise<void> {
    this.syncStatus = await gshApi.syncStatus();
    this.webhooksStatus = await gshApi.webhooksStatus();
    this.enableRefresh();
  }

  beforeDestroy(): void {
    this.disableRefresh();
  }
}
