import { groupBy, range } from "lodash";
import Vue from 'vue';
import { ActionContext } from 'vuex';
import { CurrentUpdateAction } from "./model";
import { VansByDate, OverrideResponse, OverrideResponseByNumber, OverridesByJob, SaveOverrideRequest, UpdateData, OverridesForVan } from "./overrides-model";
import { State } from "./store";
import { gshApi } from "@/lib/AxiosPlugin";
// Vue.use(Vuex);

const version = 1.1;



export type OverridesState = {
  version: number,
  overrides: OverrideResponse[],
  overridesHash: string,
}

export type OverridesContext = ActionContext<OverridesState, State>

const emptyState:OverridesState = {
  version,
  overrides: [],
  overridesHash: '',
};

function initialiseState():OverridesState{
  const overridesState = window.localStorage.getItem('overridesState');
  if(!overridesState){
    return emptyState;
  }
  try{
    const state = JSON.parse(overridesState);
    if(typeof(state.version) !== 'number' || state.version < version){
      console.log('State version has changed.');
      return emptyState;
    }
    return state;
  }catch{
    console.log('Could not unfreeze state');
    return emptyState;
  }
}

const overridesState = initialiseState();
// const state = emptyState;

export const overrides = {
  state: ():OverridesState => overridesState,
  mutations: {
    setOverrides(state:OverridesState, overrides: OverrideResponse[]):void{
      Vue.set(state, 'overrides', overrides);
    },
    setOverridesHash(state:OverridesState):void{
      Vue.set(state, 'overridesHash', JSON.stringify(state.overrides));
    },
    updateOverride(state:OverridesState, record:UpdateData):void{
      const override = state.overrides.find(o => o.orderReference == record.orderReference);
      if(!override){
        return;
      }
      if(record.enabled) override.enabled = record.enabled;
      if(record.weight) override.weight = record.weight;
      if(record.duration) override.duration = record.duration;

      Vue.set(state, 'overrides', state.overrides);
    }
  },
  actions: {
    async persistOverridesState({ state }:OverridesContext):Promise<void>{
      window.localStorage.setItem('overridesState', JSON.stringify(state));
    },
    async syncOverrides({ commit }:OverridesContext):Promise<void>{
      const overrides = await gshApi.getOverrides();
      commit('setOverrides', overrides);
      commit('setOverridesHash');
    },
    async resetOverrides({ state, commit }:OverridesContext):Promise<void>{
      try{
        const overrides = JSON.parse(state.overridesHash);
        commit('setOverrides', overrides);
      }catch(e){
        console.log('Could not reset overrides', e);
      }
    },
    async updateOverride({ commit }:OverridesContext, record:UpdateData):Promise<void>{
      commit('updateOverride', record);
      await markForUpdate(record);
    },
    async saveOverrides({ state, commit }:OverridesContext):Promise<void>{
      const overrides = extractOverrideForSave(state.overrides);
      
      await gshApi.saveOverrides(overrides);

      commit('setOverridesHash');
    }
  },
  getters: {
    overridesHaveChanged(state:OverridesState):boolean{
      return JSON.stringify(state.overrides) !== state.overridesHash;
    },
    overridesByJob(state:OverridesState):OverridesByJob[]{
      const byJob:OverrideResponseByNumber = groupBy(state.overrides, o => o.number);
  
      return Object.keys(byJob).map(key => {
        const jobs:OverrideResponse[] = byJob[key];
  
        const firstJob = jobs[0];
        const { number, subject, id } = firstJob;
  
        const startDate = jobs.reduce((startDate, job) => job.date < startDate ? job.date : startDate, 'Z');
        const endDate = jobs.reduce((endDate, job) => job.date > endDate ? job.date : endDate, '');
  
        const { isMultiVan, numberOfVans, isAllDay, isRockAndRoll } = firstJob;
  
        const deliveryOverrides = jobs.filter(job => !job.isCollection);
        const collectionOverrides = jobs.filter(job => job.isCollection);
  
        const deliveries = groupByDateAndVan(deliveryOverrides, numberOfVans);
        const collections = groupByDateAndVan(collectionOverrides, numberOfVans);
  
        return { id, number, subject, startDate, endDate, isMultiVan, 
          numberOfVans, isAllDay, isRockAndRoll, deliveries, collections, }
      })
    }
  },
}

const extractOverrideForSave = (overrides:UpdateData[]):SaveOverrideRequest[] =>
  overrides.map(override => {
    const { orderReference, weight, duration, enabled } = override;
    return { orderReference, weight: Number(weight) || 0, duration: duration || '', enabled: enabled || false }
  });

async function markForUpdate(record:UpdateData):Promise<void>{
  // console.log('markForUpdate', record);
  const { id: subject_id, subject: name } = record;

  const action = {
    subject_id,
    action_type: CurrentUpdateAction.Update,
    name,
    subject: {
      state: 3
    }
  }
  try{
    await gshApi.postWebhook({ action });
  }catch(e){
    console.log(e);
  }
}

function groupByDateAndVan(orders:OverrideResponse[], numberOfVans:number):VansByDate[]{
  const overridesByDate = groupBy(orders, d => d.date);
  return Object.keys(overridesByDate).map(date => {
    const overridesForDate = overridesByDate[date];

    const vans = calcVans(overridesForDate, numberOfVans);

    return {
      date,
      vans
    }
  })
}

function calcVans(overrides: OverrideResponse[], numberOfVans:number): OverridesForVan[] {
  if(numberOfVans === 1){
    const override = overrides[0];
    return [createCalcVanResponse(override)]
  }
  return range(1, numberOfVans+1).reduce((vans, vanIndex) => {
    const overridesForVan = overrides.find(d => d.vanIndex === vanIndex);
    if(!overridesForVan){
      return vans;
    }
    return vans.concat(createCalcVanResponse(overridesForVan));
  }, [] as OverridesForVan[])
}

function createCalcVanResponse(override:OverrideResponse):OverridesForVan{
  const { id, orderReference, enabled, duration, weight, address, subject } = override;
  return {
    id,
    orderReference,
    enabled,
    weight,
    duration,
    address,
    subject
  }
}