import { Moment } from "moment";
import { MaxoptraRunStatus } from "./MaxoptraHelper"

export enum MaxoptraLocationTypes {
  PICKUP = 'PICKUP',
  BACK_TO_HOME = 'BACK_TO_HOME',
  DROP = 'DROP',
}

export enum MaxoptraTaskTypes {
  COLLECTION = 'COLLECTION',
  DROP = 'DROP',
}

export enum MaxoptraOrderStatuses {
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
  SUSPENDED = 'SUSPENDED',
  DELIVERY_ON_MOVE = 'DELIVERY_ON_MOVE',
  DELIVERY_ARRIVED = 'DELIVERY_ARRIVED',
  DELIVERY_STARTED = 'DELIVERY_STARTED',
  DELIVERY_FINISHED = 'DELIVERY_FINISHED',
  DELIVERY_DEPARTED = 'DELIVERY_DEPARTED',
  PICKUP_ON_MOVE = 'PICKUP_ON_MOVE',
  PICKUP_ARRIVED = 'PICKUP_ARRIVED',
  PICKUP_STARTED = 'PICKUP_STARTED',
  PICKUP_FINISHED = 'PICKUP_FINISHED',
  PICKUP_DEPARTED = 'PICKUP_DEPARTED'
}

export enum OpportunityStatues {
  Open = 'Open',
  Completed = 'Completed',
  Active = 'Active',
}

export type Order = {
  _orderReference: string,
  opportunity?: Opportunity,
  _task: MaxoptraTaskTypes,
  _status: string,
  _reportedDepartureTime?: string,
  _weight: string;
  _contactNumber: string;
}

export type BaseLocation = {
  _latitude: string,
  _longitude: string,
  _factArrivalTime: string,
  _factDepartureTime: string,
  _planArrivalTime: string,
  _planDepartureTime: string,
  _planDrivingTime: string,
  _number: string;
  _estimatedArrivalTime: string;
  _estimatedDepartureTime: string;
  _address?:string;
  _planMileage: string,
}

export type StructuredOrder = {
  _contactPerson?: string,
  searchTerms?: string,
} & Order & BaseLocation;

export type Location = BaseLocation & {
  _locationType: string,
  order?: Order[],
}

export type BaseRun ={
  _runNumber: string,
  _runStartTime: string,
  _runEndTime: string,
  _planDuration: string,
  _runDay: string,
  _planDistance: string,
  _planWeight: string,
}

export type Run = {
  location: Location[],
  // startTime?: string,
} & BaseRun;

export type EnrichedRun = {
  location: Location[],
  vehicle: Partial<Vehicle>,
  startTime?: string,
} & BaseRun;

export type StructuredRun = {
  // drops: Location[],
  orders: StructuredOrder[],
  pickUp?: Location,
  backToHome?: Location,
  firstName: string,
  driverColour?: { color?: string },
  driverName: string,
  driverPhone: string,
  phone?: string,
  vehicleName: string,
  vehicleId: string,
  startTime?: string,
  runStatus: MaxoptraRunStatus,
  numRuns: number,
  estimatedBackToHomeTime?: Moment|null,
} & BaseRun;

export type Vehicle = {
  run: Run[],
  _driverName: string,
  _name: string,
  _id: string,
  _comments: string,
  driver?: Driver,
}

export type Schedule = {
  vehicle: Vehicle[],
  date: string,
}

export type OpportunityItem = {
  opportunity_item_type: number,
  name: string,
  quantity: number,
}

export type Opportunity = {
  id: number,
  subject: string,
  number:string,
  opportunity_items: OpportunityItem[],
  status_name: string,
  delivery_instructions: string,
  deliver_starts_at: string,
  deliver_ends_at: string,
  collect_starts_at: string,
  collect_ends_at: string,
  customer_collecting: boolean,
  customer_returning: boolean,
  custom_fields: {
    delivery_time?: string,
    collection_time?: string,
  },
}

export type Driver = {
  _name: string,
  _comments?: string,
  _phone?: string,
  _email?: string,
}

export type KeyValuePair = {
  key: string,
  value: string,
}

export type Printer = {
  computer: {
    hostname: string,
    id: number,
    name: string,
    state: string,
  },
  default: boolean,
  description: string,
  id: number,
  name: string,
  state: string
}

export type OrderWithZone = {
  _referenceNumber: string,
  _contactPerson?: string,
  _contactNumber?: string,
  _address: string,
  dropWindows: {
    dropWindow: {
      _endTime: string,
      _stateTime: string,
    }
  }
}

export enum CurrentUpdateAction {
  Update = 'update',
}

export type CurrentAction = {
  subject_id: number,
  action_type: CurrentUpdateAction,
  name: string,
  subject: {
    state: number
  }
}

export enum UpdateDeleteResultStatus {
  Wait = 'wait',
  Complete = 'complete',
}

export type PostWebhookRequest = {
  action: CurrentAction,
}


export type UpdateError = {
  errorCode: string,
  errorMessage: string,
}
export type UpdateWarning = {
  warningCode: string,
  warningMessage: string,
}

export type Result = {
  orderReference: string,
  status: string,
  errors?:{
    error: UpdateError[]
  },
  warnings?:{
    warning: UpdateWarning[]
  },
}

export type GroupResult = {
  status: UpdateDeleteResultStatus,
  delayFor?: number,
  result?: Result[]
}

export type UpdateDeleteResult = {
  updateResult: GroupResult,
  deleteResult: GroupResult,
}

export type QueueJob = {
  id: number,
  name: string,
  action: string,
}

export type QueueResult = {
  jobs: QueueJob[],
  nextTime: string | null,
}

export type WebhookStatus = {
  id: number,
  event: string,
  active: boolean
}

export type VersionResponse = {
  version: string;
}

export type TokenResponse = {
  token: string,
  refresh_token: string
}

export type Credentials = {
  username: string,
  password: string,
}

export type RefreshTokenRequest = {
  refresh_token: string
}