















import { MaxoptraHelper, MaxoptraRunStatus } from '@/lib/MaxoptraHelper';
import { StructuredOrder } from '@/lib/model';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class OrderViewHeading extends Vue {
  @Prop() private runStatus!: MaxoptraRunStatus;
  @Prop() private order!: StructuredOrder;

  protected rmsBaseUrl = 'https://gsh.current-rms.com';
  get opportunityId():number | null{
    return this.order.opportunity?.id || null;
  }
  get number():string{
    return MaxoptraHelper.extractNumber(this.order._orderReference) || 'No number';
  }
  get subject():string{
    return this.order.opportunity?.subject ||
      MaxoptraHelper.extractSubject(this.order._orderReference);
  }
  
  protected inProgress = MaxoptraRunStatus.IN_PROGRESS;
  protected ended = MaxoptraRunStatus.IN_PROGRESS;
}
