import { MaxoptraTaskTypes, Order, StructuredOrder, StructuredRun } from "@/lib/model";
import { CurrentHelper } from "./CurrentHelper";
import { MaxoptraHelper, MaxoptraRunStatus } from "./MaxoptraHelper";

export type GshStatus = {
  className: string,
  content: string,
}

export class Gsh{
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static jobStatus(order:Order, run?:StructuredRun, estimatedArrivalTime?: string):GshStatus{
    const isDelivery = MaxoptraHelper.isDelivery(order);
    const isCollection = MaxoptraHelper.isCollection(order);
    const jobRejected = MaxoptraHelper.jobRejected(order);
    const jobComplete = MaxoptraHelper.jobComplete(order);
    const opportunityStatusActive = order.opportunity && CurrentHelper.opportunityStatusActive(order.opportunity);
    const opportunityStatusComplete = order.opportunity && CurrentHelper.opportunityStatusComplete(order.opportunity);
    
    if(!run){
      return createStatus('', '');
    }

    if(run.runStatus == MaxoptraRunStatus.NOT_STARTED){
      if(isCollection){
        return createStatus('ready', 'Ready');
      }
      if(isDelivery){
        if(opportunityStatusActive){
          return createStatus('ready', 'Ready');
        }else{
          return createStatus('load', 'Load');
        }
      }
    }
    
    if(run.runStatus == MaxoptraRunStatus.IN_PROGRESS){
      if(jobComplete){
        return createStatus('completed', 'Complete');
      }
      if(jobRejected){
        return createStatus('failed', 'Failed');
      }
      if(!jobComplete && !jobRejected){
        // return createStatus('on-route', `${estimatedArrivalTime?.split(' ').pop() || 'No ETA'} / On route`);
        return createStatus('on-route', 'On Route');
      }
    }

    if(run.runStatus == MaxoptraRunStatus.ENDED){
      if((!isDelivery && order.opportunity && !opportunityStatusComplete) ||
        (isDelivery && jobRejected && order.opportunity && opportunityStatusActive)){
        return createStatus('unload', 'Unload');
      }
      if((!isDelivery && order.opportunity && opportunityStatusComplete) ||
        (isDelivery && jobRejected && order.opportunity && !opportunityStatusActive)){
        return createStatus('unloaded', 'Unloaded');
      }
    }

    return createStatus('', '');
  }

  public static runStatus(run: StructuredRun):GshStatus | undefined{
    if(run.runStatus == MaxoptraRunStatus.NOT_STARTED){
      const hasNonActiveDeliveries = run.orders
        .filter(order => MaxoptraHelper.isDelivery(order))
        .filter(order => order.opportunity && !CurrentHelper.opportunityStatusActive(order.opportunity))
        .length > 0

      if(hasNonActiveDeliveries){
        return createStatus('load', 'Load');
      }
      return createStatus('ready', 'Ready');
    }
    
    if(run.runStatus == MaxoptraRunStatus.IN_PROGRESS){
      return createStatus('still-out', '(Still out)');
    }

    if(run.runStatus == MaxoptraRunStatus.ENDED){
      const hasActiveFailedDeliveries = run.orders
        .filter(order => MaxoptraHelper.isDelivery(order) && MaxoptraHelper.jobRejected(order))
        .filter(order => order.opportunity && CurrentHelper.opportunityStatusActive(order.opportunity))
        .length > 0

      const hasActiveCollections = run.orders
        .filter(order => !MaxoptraHelper.isDelivery(order))
        .filter(order => order.opportunity && CurrentHelper.opportunityStatusActive(order.opportunity))
        .length > 0

      if(hasActiveFailedDeliveries || hasActiveCollections){
        return createStatus('unload', 'Unload');
      }
      return createStatus('empty', 'Empty');
    }
  }

  public static humanReadableTime(order:StructuredOrder):string{
    return (order._task === MaxoptraTaskTypes.DROP ?
      order.opportunity?.custom_fields.delivery_time :
      order.opportunity?.custom_fields.collection_time) || 'N/A';
  }
}

function createStatus(className: string, content:string): GshStatus {
  return { className, content };
}
