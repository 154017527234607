





























import { Component, Vue } from 'vue-property-decorator';
import OverrideDetails from '@/components/OverrideDetails.vue';
import { range } from 'lodash';
import moment, { Moment } from 'moment';
import { OrderWithZone } from '@/lib/model';
import { OverridesByJob, UpdateData } from '@/lib/overrides-model';
import '@/styles/override-styles.css';

@Component({
  components: {
    OverrideDetails
  },
})
export default class Overrides extends Vue {
  locked = true;
  startDate = moment();
  numDays = 5;
  days = range(5);
  status = '';

  get overridesByJob():OverridesByJob[]{
    return this.$store.getters.overridesByJob;
  }

  get orderData():OrderWithZone[]{
    return this.$store.state.dashboard.ordersWithZone;
  }

  createDate(offset:number):Moment{
    return moment(this.startDate).add(offset, 'days');
  }
  getStartDate():Moment{
    return moment(this.startDate);
  }
  changeDay(inc:number):void{
    this.startDate = moment(this.startDate).add(inc, 'days');
    this.downloadOrderData();
  }
  updateData(record:UpdateData):void{
    // console.log('updateData', record);
    this.$store.dispatch('updateOverride', record);
  }
  unlock():void{
    console.log('unlocking')
    this.locked = false;
  }
  async save():Promise<void>{
    if(this.$store.getters.overridesHaveChanged){
      if(confirm('Are you sure you want to save?')){
        console.log('saving')
        this.locked = true;
        await this.$store.dispatch('saveOverrides');
        this.setStatus('Changes saved');
      }
    }else{
      this.setStatus('Overrides have not changed');
    }
  }
  cancel():void{
    console.log('canceling')
    this.locked = true;
    this.$store.dispatch('resetOverrides');
  }
  async reload():Promise<void>{
    if(this.$store.getters.overridesHaveChanged){
      if(confirm('This will erase ')){
        console.log('reloading');
        await this.loadData();
      }else{
        console.log('reloading rejected')
      }
    }else{
      await this.loadData();
    }
    this.locked = true;
  }

  setStatus(status:string):void{
    this.status = status;
    setTimeout(this.clearStatus, 2500);
  }
  clearStatus():void{
    this.status = ''
  }

  async loadData():Promise<void>{
    await this.$store.dispatch('syncOverrides');
    await this.downloadOrderData();
  }
  async downloadOrderData():Promise<void>{
    (await Promise.all(range(this.numDays).map(async (offset:number):Promise<void> => {
      const date = moment(this.startDate).add(offset, 'days').format('YYYY-MM-DD');
      await this.$store.dispatch('syncOrdersWithZone', date);
    })));
  }
  mounted():void{
    this.loadData();
  }
}

