import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class ToastMixin extends Vue {
  makeToast(title: string, text:string):void {
    this.$bvToast.toast(text, {
      title,
      autoHideDelay: 6000,
      appendToast: true
    })
  }
}
