










































import { Component, Vue } from 'vue-property-decorator'
import ReportProblem from './components/ReportProblem.vue';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import { LIB_VERSION } from "@/version";
import { gshApi } from "@/lib/AxiosPlugin";

@Component({
  components: {
    ReportProblem,
    Home,
    Login
  }
})
export default class App extends Vue {
  protected menuItems = [
    addRoute('LoadingBay', '/'),
    addRoute('Warehouse'),
    addRoute('Update'),
    // addRoute('Overrides'),
    // addRoute('Planning'),
  ];

  protected endpoint = process.env.VUE_APP_ENDPOINT as string;
  protected mode = process.env.VUE_APP_MODE as string;
  protected version = LIB_VERSION;
  protected backendVersion = '';

  get loggedIn():boolean{
    // return this.$store.getters.loggedIn;
    return this.$store.state.auth.loggedIn;
  }

  logout():void{
    this.$store.dispatch('doLogout');
  }

  async mounted():Promise<void>{
    await this.$store.dispatch('checkAuth');
    this.backendVersion = (await gshApi.version()).version;
  }
}

const addRoute = (name: string, to = `/${name}`) => ({ name, to });
