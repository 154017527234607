import {
  BaseLocation,
  Driver,
  KeyValuePair,
  Location,
  MaxoptraLocationTypes,
  MaxoptraOrderStatuses,
  MaxoptraTaskTypes,
  Order,
  Run,
  StructuredOrder,
  StructuredRun
} from "@/lib/model"
import moment, {Moment} from "moment-timezone"
import {CurrentHelper} from "./CurrentHelper"
import {createKeyPair, splitLines} from "./utils";

export enum MaxoptraRunStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  ENDED = "ENDED",
}

export enum MaxoptraVanStatus {
  STILL_OUT = "STILL_OUT",
  LOAD = "LOAD",
  READY = "READY",
  UNLOAD = "UNLOAD",
  EMPTY = "EMPTY",
}

const emptyLocation: Location = {
  _estimatedArrivalTime: "",
  _estimatedDepartureTime: "",
  _factArrivalTime: "",
  _factDepartureTime: "",
  _latitude: "",
  _locationType: "",
  _longitude: "",
  _number: "",
  _planArrivalTime: "",
  _planDepartureTime: "",
  _planDrivingTime: "",
  _planMileage: ""
}

export class MaxoptraHelper {
  static extractNumber(orderReference:string):string{
    return MaxoptraHelper.extractRefParts(orderReference).number;
  }
  static extractSubject(orderReference:string):string{
    return MaxoptraHelper.extractRefParts(orderReference).orderReference;
  }
  private static extractRefParts(orderReference:string){
    const match = orderReference.match(/(\d{5,13})[cd](.+)/);
    if(!match){
      return {
        number: '',
        orderReference
      }
    }
    return {
      number: match[1],
      orderReference: match[3]
    }
  }
  static extractTime(maxoptraDateTime: string):string {
    return maxoptraDateTime.split(' ').pop() || '';
  }
  static extractDate(maxoptraDateTime: string):string {
    return maxoptraDateTime.split(' ').pop() || '';
  }
  static getDriverColour(driver:Driver): string{
    return MaxoptraHelper.getDriverAttribute(driver, 'Colour') || '';
  }
  static getDriverAttribute(driver:Driver, attribute:string):string|undefined{
    if(!driver._comments){
      return '';
    }
    const attributes = splitLines(driver._comments).reduce((attributes, str) => {
      const kvPair =  createKeyPair(str);
      return !kvPair ? attributes : attributes.concat(kvPair);
    }, [] as KeyValuePair[]);

    return attributes.find(a => a.key === attribute)?.value;
  }

  // static runStatus(run: StructuredRun):MaxoptraRunStatus{
  //   const pickup = run.pickUp;
  //   const backToHome = run.backToHome;
  //   const drops = run.drops;

  //   return this.runStatusCommon(drops, pickup, backToHome);
  // }

  static runStatusCommon(orders: StructuredOrder[], pickup?: Location, backToHome?: Location):MaxoptraRunStatus{
    if(this.runEnded(orders, backToHome)){
      return MaxoptraRunStatus.ENDED;
    }
    if(this.runStarted(orders, pickup)){
      return MaxoptraRunStatus.IN_PROGRESS;
    }
    return MaxoptraRunStatus.NOT_STARTED;
  }
  static runStarted(orders: StructuredOrder[], pickup?: Location):boolean{
    if(pickup?._factDepartureTime){
      return true
    }

    for(let ii = 0; ii < orders.length; ii++){
      if(this.jobHasStarted(orders[ii])){
        return true;
      }
    }
    return false;
  }
  static runEnded(orders: StructuredOrder[], backToHome?: Location):boolean{
    if(backToHome?._factArrivalTime){
      return true;
    }
    try{
      return moment().isAfter(this.estimatedBackToHomeTime(orders, backToHome));
    }catch(e){
      return false;
    }
  }
  static runEnded2(run:StructuredRun):boolean{
    if(run.backToHome?._factArrivalTime){
      return true;
    }
    try{
      return moment().isAfter(run.estimatedBackToHomeTime);
    }catch(e){
      return false;
    }
  }
  static estimatedBackToHomeTime(orders: StructuredOrder[], backToHome?: Location):Moment|null{
    if(orders.length === 0){
      return null;
    }
    const lastOrder = orders[orders.length - 1];

    const reportedDepartureTime = lastOrder._reportedDepartureTime
    if(this.jobHasStarted(lastOrder) && reportedDepartureTime){
      return moment(this.formatMaxoptraDate(reportedDepartureTime)).add(this.backToHomeDrivingTime(backToHome), 'minutes')
    }
    return null;
  }
  static backToHomeDrivingTime(backToHome?: Location):string|undefined{
    return backToHome?._planDrivingTime;
  }
  
  static jobHasStarted(order: StructuredOrder):boolean{
    const statuses = [
      'CLOSED','FAILED','SUSPENDED','DELIVERY_ON_MOVE','DELIVERY_ARRIVED','DELIVERY_STARTED','DELIVERY_FINISHED','DELIVERY_DEPARTED','PICKUP_ON_MOVE','PICKUP_ARRIVED','PICKUP_STARTED','PICKUP_FINISHED','PICKUP_DEPARTED'
    ]
    return statuses.indexOf(order._status) > -1;
  }

  static runEndedAndVanEmpty(run: StructuredRun):boolean{
    const backToHome = run.backToHome;

    return this.runEnded(run.orders, backToHome) && this.vanEmpty(run)
  }
  static vanEmpty(run: StructuredRun):boolean{
    let vanEmpty = true
    try{
      const collections = run.orders
        .filter((order:Order) => order._task === MaxoptraTaskTypes.COLLECTION);

      if(collections.length){
        vanEmpty = false
      }
      const inCompleteOpportunities = run.orders.map((order: Order) => order.opportunity && !CurrentHelper.opportunityStatusComplete(order.opportunity))
      if(!inCompleteOpportunities.length){
        vanEmpty = true
      }
    }catch(e){
      //do nothing
    }
    return vanEmpty
  }
  static pickUpLocation(run: Run):Location | undefined{
    if(!run.location){
      return emptyLocation;
    }
    return run.location.find((loc: Location) => loc._locationType === MaxoptraLocationTypes.PICKUP);
  }
  static backToHomeLocation(run: Run):Location | undefined{
    if(!run.location){
      return emptyLocation;
    }
    return run.location.find((loc: Location) => loc._locationType === MaxoptraLocationTypes.BACK_TO_HOME);
  }
  static dropLocations(run: Run):Location[] {
    if(!run.location){
      return [];
    }
    return run.location.filter((loc: Location) => loc._locationType === MaxoptraLocationTypes.DROP);
  }

  static formatMaxoptraDate(datetime: string):string{
    //in: dd/mm/yyyy hh:mm
    //to: yyyy-mm-dd hh:mm
    try{
      const datetimeParts = datetime.split(' ')
      const dateParts = datetimeParts[0].split('/')
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]} ${datetimeParts[1]}`
    }catch(e){
      return datetime
    }
  }

  public static jobStatus(order:Order):string{
    return order._status
  }
  public static isCollection(order:Order):boolean{
    return order._task === MaxoptraTaskTypes.COLLECTION;
  }
  public static isDelivery(order:Order):boolean{
    return order._task === MaxoptraTaskTypes.DROP;
  }
  public static jobComplete(order:Order):boolean{
    return order._status === MaxoptraOrderStatuses.CLOSED;
  }
  public static jobRejected(order:Order):boolean{
    return order._status === MaxoptraOrderStatuses.FAILED;
  }
  public static dateToMoment(date:string):Moment{
    return moment(date, "DD/MM/YYYY HH:mm").utcOffset('Europe/London');
  }

  public static calculateLeaveBy(location:BaseLocation):string{
    if(!location._planArrivalTime){
      return '_planArrivalTime not set';
    }
    const planArrivalMoment = this.dateToMoment(location._planArrivalTime);
    const travelTime = Math.ceil(parseInt(location._planDrivingTime) * 1.1); //add 10%
    return planArrivalMoment
        .subtract(travelTime, 'minutes')
        .format('HH:mm');
  }
  public static partialPostcode(address:string):string{
    return address.trim().split(',').pop()?.trim().split(' ').shift() || 'No address';
  }
  // prepareAddress(address:string):string{
  //   const match = address.match(/^(.+), United Kingdom$/);
  //   if(!match){
  //     return address;
  //   }
  //   return match[1].trim();
  // }
}