import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export class SyncMixin extends Vue {
  private date = moment();

  protected searchTerm = '';
  protected syncing = false;
  protected autoActive = true;
  
  private interval:number | undefined = undefined;

  get formattedDate():string{
    return this.date.format('YYYY-MM-DD');
  }

  get displayDate():string{
    return this.date.format('dddd - DD MMM YYYY');
  }

  private async automatedTasks(){
    // this.$store.dispatch('updateStartTimes', this.formattedDate);

    if(this.autoActive){
      await this.refreshData();
    }
  }
  private async refreshData(){
    this.syncing = true;
    await this.$store.dispatch('refreshAllData', this.formattedDate);
    this.syncing = false;
  }

  toggleAuto():void{
    this.autoActive = !this.autoActive;
  }
  mounted():void{
    this.interval = window.setInterval(this.automatedTasks, 30000);
  }

  beforeDestroy():void{
    clearInterval(this.interval);
  }

  async changeDate(inc:number):Promise<void>{
    this.date = moment(this.date).add(inc, 'days');
    if(!this.$store.getters.hasRunsForDate(this.formattedDate)){
      await this.syncForDate(this.formattedDate);
    }
  }
  async today():Promise<void>{
    this.date = moment();
    if(!this.$store.getters.hasRunsForDate(this.formattedDate)){
      await this.syncForDate(this.formattedDate);
    }
  }

  private async syncForDate(date:string):Promise<void>{
    await this.$store.dispatch('syncAll', date);
  }
}