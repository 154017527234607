
















import { Component, Prop } from 'vue-property-decorator';
import { LocationTimes } from '@/mixins/LocationTimes';
import { mixins } from 'vue-class-component';
import { StructuredRun } from '@/lib/model';
import {Gsh, GshStatus} from '@/lib/gsh';

@Component({})
export default class BackToHome extends mixins(LocationTimes) {
  @Prop() protected run!: StructuredRun;

  get runStatus(): GshStatus | undefined{
    return Gsh.runStatus(this.run);
  }
}
