import { Opportunity, Order, Schedule, Location, 
  // EnrichedRun, MaxoptraLocationTypes,
  StructuredRun, StructuredOrder, BaseLocation, MaxoptraTaskTypes, OrderWithZone } from "@/lib/model";
import { DashboardState } from "./dashboard-store";
import { omit } from 'lodash';
import { MaxoptraHelper } from "./MaxoptraHelper";
import { Moment } from "moment";
import moment from "moment";

export const filterAndHydrate = (state: DashboardState, date:string, searchText:string,
  filterFunc:(run:StructuredRun) => boolean):StructuredRun[] => {
  const runs = state.runs[date];
  if(!runs){
    return [];
  }
  const filteredRuns = runs.filter(filterFunc)

  const runsWithDrivers = filteredRuns
    .map(run => {
      const driver = state.drivers.find(d => d._name === run.driverName);
      if(!driver){
        return run;
      }
      const driverColour = { color: `#${MaxoptraHelper.getDriverColour(driver)}` };
      const driverPhone = driver._phone;

      return Object.assign({}, run, { driverColour, driverPhone })
    }); 

  const hydratedRuns = hydrateWithOpps(runsWithDrivers, state.opportunities);
  return searchRuns(hydratedRuns, searchText);
}

export const filterWarehouseAndHydrate = (state: DashboardState, date:string, searchText:string,
  filterFunc:(order:StructuredOrder) => boolean):StructuredOrder[] => {
  const runs = state.runs[date];
  if(!runs){
    return [];
  }
  const warehouse = findWarehouse(runs);
  if(!warehouse){
    return [];
  }
  const filteredOrders = warehouse.orders.filter(filterFunc);

  const searchedOrders = searchOrders(filteredOrders, searchText);
  return searchedOrders.map(order => hydrateOrder(order, state.opportunities));
}

export const finishedRuns = (runs:StructuredRun[]):StructuredRun[] => {
  return runs.filter(run => MaxoptraHelper.runEnded2(run));
}
export const unfinishedRuns = (runs:StructuredRun[]):StructuredRun[] => {
  return runs.filter(run => !MaxoptraHelper.runEnded2(run));
}

export const morningRuns = (runs:StructuredRun[]):StructuredRun[] => {
  return runs.filter(run => MaxoptraHelper.dateToMoment(run._runStartTime).format('HHmm') < '1200');
}

export const afternoonRuns = (runs:StructuredRun[]):StructuredRun[] => {
  return runs.filter(run => MaxoptraHelper.dateToMoment(run._runStartTime).format('HHmm') >= '1200' && MaxoptraHelper.dateToMoment(run._runStartTime).format('HHmm') < '1700');
}

export const eveningRuns = (runs:StructuredRun[]):StructuredRun[] => {
  return runs.filter(run => MaxoptraHelper.dateToMoment(run._runStartTime).format('HHmm') >= '1700');
}

export const searchRuns = (runs:StructuredRun[], searchText = ''):StructuredRun[] => {
  if(!searchText){
    return runs;
  }
  return runs.map(run => {
    const orders = searchOrders(run.orders, searchText);
    return Object.assign({}, run, { orders });
  }).filter(run => run.orders.length)
}

export const searchOrders = (orders:StructuredOrder[], searchText = ''):StructuredOrder[] => {
  if(!searchText){
    return orders;
  }
  return orders.filter(order => matchOrder(order, searchText));
}

function matchOrder(order:StructuredOrder, searchText:string):boolean{
  return !!order.searchTerms?.includes(searchText.toLowerCase());
}

type RunCount = {
  [driverName:string]: number
}

export const reformatScheduleAsRuns = (schedule:Schedule, ordersWithZone:OrderWithZone[]):StructuredRun[] => {
  const driverVanRuns = schedule.vehicle.reduce((runCount, vehicle) => 
    Object.assign({}, runCount, { [vehicle._driverName]: vehicle.run.length })
  , {} as RunCount);

  return schedule.vehicle.flatMap(vehicle => vehicle.run.map(run => {
    const { _runStartTime, _runEndTime, _runDay, _planDuration, _runNumber, _planDistance, _planWeight } = run;

    const firstName = vehicle?._driverName?.split(' ').shift() || vehicle._driverName || '';
    
    const pickUp = MaxoptraHelper.pickUpLocation(run);
    const backToHome = MaxoptraHelper.backToHomeLocation(run);
    const drops = MaxoptraHelper.dropLocations(run) || [];
    const baseOrders = formatOrders(MaxoptraHelper.dropLocations(run) || []);
    const hydratedOrders = hydrateWithOrders(baseOrders, ordersWithZone);
    const vehicleName = vehicle?._name || '';
    const vehicleId = vehicle?._id || '';
    const driverName = vehicle?._driverName || '';
    const driverPhone = vehicle?.driver?._phone || '';
    const orders = updateSearchTerms(hydratedOrders, [vehicleName, driverName]);
    const runStatus = MaxoptraHelper.runStatusCommon(orders, pickUp, backToHome);
    const estimatedBackToHomeTime = MaxoptraHelper.estimatedBackToHomeTime(orders, backToHome);
    const numRuns = driverVanRuns[vehicle?._driverName] || 1;

    return { runStatus, vehicleName, vehicleId, _runNumber, numRuns,
      vehicle, firstName, pickUp, backToHome, drops, orders, driverName, driverPhone,
      _runStartTime, _runEndTime, _runDay, _planDuration, _planDistance,
      _planWeight, estimatedBackToHomeTime };
  })).sort(sortRunByTime);
}

function formatOrders(locations:Location[]):StructuredOrder[]{
  return locations.reduce((orders, location) => {
    if(!location.order){
      return orders;
    }
    const baseLocation = extractLocationAttributes(location);
    return orders.concat(location.
      order?.map((order:Order) => Object.assign({}, order, omit(baseLocation, 'order'))))
  }, [] as StructuredOrder[])
}

function hydrateWithOrders(orders:StructuredOrder[], ordersWithZone:OrderWithZone[]){
  return orders.map(order => {
    const orderWithZone = ordersWithZone.find(oz => oz._referenceNumber === order._orderReference);
    if(!orderWithZone){
      return order;
    }
    const { _contactPerson } = orderWithZone;
    return Object.assign({}, order, { _contactPerson })
  })
}

function updateSearchTerms(orders: StructuredOrder[], extra: string[]): StructuredOrder[] {
  return orders.map(order => {
    const searchTerms = [
      order._orderReference,
      order._contactNumber,
      order._contactPerson,
    ].concat(extra).map(s => s?.toLowerCase()).join(' ');
    return Object.assign({}, order, order, { searchTerms });
  })
}

function extractLocationAttributes(location:BaseLocation):BaseLocation{
  const { _latitude, _longitude, _factArrivalTime, _factDepartureTime,
    _planArrivalTime, _planDepartureTime, _planDrivingTime, _number,
    _estimatedArrivalTime, _estimatedDepartureTime, _address, _planMileage } = location;
  return { _latitude, _longitude, _factArrivalTime, _factDepartureTime,
    _planArrivalTime, _planDepartureTime, _planDrivingTime, _number,
    _estimatedArrivalTime, _estimatedDepartureTime, _address, _planMileage }
}

function sortRunByTime(a:StructuredRun, b:StructuredRun):number{
  const aDTime = a.pickUp?._planDepartureTime || '';
  const bDTime = b.pickUp?._planDepartureTime || '';

  if(aDTime < bDTime) return -1
  if(aDTime > bDTime) return 1
  return 0
}

export function filterVans(run:StructuredRun):boolean{
  return run.driverName !== 'A' && hasCommittedOrders(run);
}

function hasCommittedOrders(run:StructuredRun):boolean{
  return run.orders.filter(isCommittedOrder).length > 0;
}

function isCommittedOrder(order:StructuredOrder):boolean{
  return order._status !== "ALLOCATED";
}

export function findWarehouse(runs:StructuredRun[]):StructuredRun|undefined{
  return runs.find(run => run.driverName === 'A');
}

export function filterWarehouseCollections(order:StructuredOrder):boolean{
  return order._task === MaxoptraTaskTypes.DROP && isCommittedOrder(order);
}

export function filterWarehouseReturns(order:StructuredOrder):boolean{
  return order._task === MaxoptraTaskTypes.COLLECTION && isCommittedOrder(order);
}

export function hydrateWithOpps(runs:StructuredRun[], opportunities:Opportunity[]):StructuredRun[]{
  return runs.map(run => hydrateRun(run, opportunities));
}

function hydrateRun(run:StructuredRun, opportunities:Opportunity[]){
  return Object.assign({}, run, { orders: run.orders.map(order => hydrateOrder(order, opportunities)) })
}

function hydrateOrder(order:StructuredOrder, opportunities:Opportunity[]){
  const num = extractNumberFromRef(order._orderReference);
  const opportunity = opportunities.find((o:Opportunity) => o.number === num);

  if(!opportunity){
    return order;
  }
  const additionalTerms = opportunity.opportunity_items.map(item => 
      item.name.toLowerCase()).join(' ');

  const searchTerms = [order.searchTerms, additionalTerms].join(' ')

  return Object.assign({}, order, { opportunity, searchTerms });
}

export function extractNumbersFromRun(run: StructuredRun[]):string[]{
  return run.flatMap(run => run.orders
    .reduce(extractNumbersFromOrder, [] as string[]));
}

export function extractNumbersFromOrder(numbers:string[], order: Order):string[]{
  const oppNumber = extractNumberFromRef(order._orderReference);
  if(!oppNumber){
    return numbers;
  }
  return numbers.concat(oppNumber);
}

export function extractNumberFromRef(orderReference:string):string|undefined{
  if(!orderReference){
    return undefined;
  }
  const numberRE = /^\d{5,11}/
  const matches = numberRE.exec(orderReference)
  if(!matches){
    return undefined;
  }return matches[0];
}

export function updateRunStartTimes(runs:StructuredRun[], currentTime:Moment):StructuredRun[]{
  return runs.map(run => {
    if(!run.pickUp){
      return run;
    }
    const startTime = calcStartTime(run.pickUp, currentTime);
    return Object.assign({}, run, { startTime });
  })
}

function calcStartTime(pickup:Location, currentTime:Moment):string{
  const planDepartureMoment = MaxoptraHelper.dateToMoment(pickup._planDepartureTime);
  const factDepartureMoment = MaxoptraHelper.dateToMoment(pickup._factDepartureTime);

  const minutes = Math.round(moment.duration(planDepartureMoment.diff(currentTime)).as('minutes'));
  if(minutes < 60 && currentTime.toISOString() < planDepartureMoment.toISOString()){
    return `in ${minutes} minutes`;
  }
  const planDepartureTime = planDepartureMoment.format('HH:mm');
  if(factDepartureMoment.isValid()){
    const factDepartureTime = factDepartureMoment.format('HH:mm');
    return `${planDepartureTime} (${factDepartureTime})`;
  }else{
    return planDepartureTime;
  }
}
