








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DeliveryInstructions extends Vue {
  @Prop() private deliveryInstructions!: string;

  show = false;

  get text(): string{
    if(this.show){
      return this.deliveryInstructions;
    }
    return this.deliveryInstructions.substring(0, 10) + '... (more)';
  }

  toggle(): void{
    this.show = !this.show
  }
}
